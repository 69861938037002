import React from "react";
import { RenderIconPara } from "../../common/Common";

import "./Extracurricular.css";
import ExtracurricularSlider from "./ExtracurricularSlider";
import { FiCheck } from "react-icons/fi";


export default function Extracurricular() {
  return (
    <>
      <div className="container">
        <div className="d-flex d-flex-row two-columns h-100">
          <div className="column">
            <h2>Extracurriculars</h2>
            <div className="seperator" />
            <div className="desk-content tour-content tour-content-list">
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"Watering their seed of energy!"}
                paraClass={"contact-icon-info para-class"}
              />
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"A healthy muscle will lead to a healthy mind play!"}
                paraClass={"contact-icon-info para-class"}
              />
               <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"Time to revitalize their muscles!"}
                paraClass={"contact-icon-info para-class"}
              />
            </div>
          </div>
        </div>
        
        {/* <ExtracurricularSlider /> */}
        
      </div>
    </>
  );
}
