import "./footer-styles.css"
import Logo from "../navbar/Logo";
import { AiFillFacebook, AiOutlineInstagram, AiOutlineYoutube } from 'react-icons/ai';

export default function Footer () {
    return (
        <div>
            <div className = "footer-container">
            <div className="logo-footer">
                <Logo />
            </div>

            <div style = {{marginTop: 30}}></div>
            <p className = "footer-content">
                457, Kanuvai Thudiyalur Road, Pannimadai Post, Coimbatore
            </p>
            <p className = "footer-content">
                Tamil Nadu - 641017. <a href="tel:919092845000" style={{color: 'white'}}> Ph. +91 90928 45000</a>
            </p>
            <p className = "footer-content footer-content-view">
              Follow us on: 
              <a href="https://m.facebook.com/pages/category/Education/Avani-Public-School-137648843541640/" className="social-links">
                  <AiFillFacebook />
              </a>
              <a href="https://youtube.com/channel/UCKEC3E053grTWvQXEyhPAzw" className="social-links">
                  <AiOutlineYoutube />
              </a>
              <a href="https://www.instagram.com/avanipublicschool?igsh=MTFpNWFqd3ViMTdiMg==" className="social-links">
                  <AiOutlineInstagram />
              </a>
            </p>
           
            <div style = {{marginTop: 30}}></div> 
        </div>
        <div className="footer-copy" style = {{backgroundColor: "#1d1d1d", display: "flex", flexDirection: "row", justifyContent:"space-between", alignItems: 'center'}}>
        <p className = "footer-content">
            © {new Date().getFullYear()} Avani Public School,
        </p>
        <p className="footer-content"> All Rights Reserved</p>
        <p className = "footer-content">
        <a href="tel:919092845000" style={{color: 'white'}}> Ph. +91 90928 45000</a>
        </p>
        </div>
        </div>
    );
}
