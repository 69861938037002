import AboutLanding from "../components/about/Landing/AboutLanding";
import AboutView from "../views/about.view";
import AdmissionView from "../views/admission.view";
import ContactView from "../views/contact.view";
import CurriculumView from "../views/curriculum.view";
import HomeView from "../views/home.view";
import Program from '../components/programs/Program'; 
import AcademicsComponent from "../components/academics/AcadmicsComponent";
import FacilitiesView from "../views/facilities.view";
import SchoolView from "../views/school.view";
import { GalleryView } from "../views/gallery.view";

const routes = [
    {
        name: 'Home',
        url: '/',
        component: HomeView
    },
    {
        name: 'About Us', 
        url: '/about-us',
        component: AboutLanding
    },
    {
        name: 'Facilities', 
        url: '/our-facilities',
        component: FacilitiesView
    },
    {
        name: 'Admission', 
        url: '/admissions',
        component: AdmissionView
    },
    {
        name: 'Curriculum', 
        url: '/our-curriculum',
        component: CurriculumView
    },
    {
        name: 'Programs', 
        url: '/programs',
        component: Program
    },
    {
        name: 'School Info', 
        url: '/school-information',
        component: SchoolView
    },
    {
        name: 'Academics', 
        url: '/academics',
        component: AcademicsComponent
    },
    {
        name: 'Gallery', 
        url: '/gallery',
        component: GalleryView
    },
    {
        name: 'Contact Us', 
        url: '/contact-us',
        component: ContactView
    }
]

export default routes; 