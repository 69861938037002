import {
  FiMessageCircle,
  FiNavigation2,
  FiPhoneCall
} from "react-icons/fi";
import MandatoryDisclosure from "../about/disclosure/MandatoryDisclosure";
import GradeStudentTable from "./GradeStudentTable";

const area = {
  heading: "Area of the school campus",
  contents: [
    {
      heading: "Total area (in square meters )on which the school is situated",
      value: "12671.28",
    },
    {
      heading: "In Acres",
      value: "3 Acres and 13 cent",
    },
    {
      heading: "Built up area (mtrs)",
      value: "5376.76",
    },
    {
      heading: "Area of playground(mtrs)",
      value: "6637",
    },
  ],
};

const transport = {
  heading: "Transport facility",
  contents: [
    {
      heading: "Details of transport charges ",
      value: "7000 per term",
    },
  ],
};

const faculty = {
  heading: "Faculty Details",
  contents: [
    {
      heading: "Number of NTTS",
      value: "10",
    },
    {
      heading: "Number of PRTs",
      value: "20",
    },
    {
      heading: "Number of TGTs/PGTs",
      value: "10",
    },
    {
      heading: "Number of PETs/PTI",
      value: "03",
    },
    {
      heading: "Other Non-Teaching Staff",
      value: "19",
    },
    {
      heading: "Whether Special Educator Appointed",
      value: "Yes",
    },
    {
      heading: "Whether Counsellor and Wellness Teacher Appointed?",
      value: "Yes",
    },
  ],
};

const facilities = {
  heading: "Other facilities",
  contents: [
    {
      heading: "Swimming Pool",
      value: "Yes",
    },
    {
      heading: "Indoor Games",
      value: "Yes",
    },
    {
      heading: "Dance Room",
      value: "Yes",
    },
    {
      heading: "Gymnasium",
      value: "No",
    },
    {
      heading: "Music Room",
      value: "Yes",
    },
    {
      heading: "Hostel",
      value: "No",
    },
    {
      heading: "Health And Medical Checkup",
      value: "Yes",
    },
  ],
};

const payment = {
  heading: "Mode of payment of salary",
  contents: [
    {
      heading: "Name of The Bank Through Which Salary Is Drawing",
      value: (
        <>
          HDFC BANK <br />
          No.553 & 554,Thadagam Road, <br />
          Somyampalayam , Coimbatore -641108.
        </>
      ),
    },
    {
      heading: "Through Single Cheque Transfer",
      value: "Yes",
    },
    {
      heading: "Cash",
      value: "No",
    },
    {
      heading: "Individual Cheque",
      value: "No",
    },
  ],
};

const library = {
  heading: "Library facilities",
  contents: [
    {
      heading: "Size of the Library",
      value: "44’10’’ * 26’0’’",
    },
    {
      heading: "No.of Periodicals & Magazine",
      value: "14",
    },
    {
      heading: "No.of Dailies",
      value: "04",
    },
    {
      heading: "No.of Reference Books",
      value: "2500",
    },
  ],
};

const totalStudents = {
  contents: [
    {
      heading: "Boys",
      value: "149",
    },
    {
      heading: "Girls",
      value: "125",
    },
    {
      heading: "Total",
      value: "274",
    },
  ],
};

const staff = {
  heading: "Staff Strength",
  contents: [
    {
      heading: "Montessori Staff",
      value: "19",
    },
    {
      heading: "Montessori Non-teaching Staff ",
      value: "01",
    },
    {
      heading: "Grade staff",
      value: "20",
    },
    {
      heading: "Grade Non-teaching Staff",
      value: "02",
    },
    {
      heading: "Part-time staff",
      value: "02",
    },
    {
      heading: "House Keeping Montessori Environment",
      value: "06",
    },
    {
      heading: "House Keeping Grade 1 to 5",
      value: "03",
    },
  ],
};

const greivance = {
  heading: "Name of the Grievance / Redressal Officer with E-mail and Tel No",
  header: [
    { title: "Name" },
    { title: "Designation" },
    { title: "Email" },
    { title: "Mobile No." },
  ],
  threeRow: true,
  contents: [
    {
      heading: "Ms Malini. A",
      value: [
        "Principal",
        <>
          <a href="mailto://principal@avanipublicschool.com">
            principal@avanipublicschool.com
          </a>
        </>,
        <>
          <a href="tel://919092845000">9092845000</a>
        </>,
      ],
    },
    {
      heading: "Ms Divya S",
      value: ["Coordinator"],
    },
    {
      heading: "Ms Mathangi Prasad",
      value: ["Facilitator"],
    },
  ],
};

const harrasment = {
  heading: "Members of the Sexual Harassment Committee",
  header: [
    { title: "Name" },
    { title: "Designation" },
    { title: "Email" },
    { title: "Mobile No." },
  ],
  threeRow: true,
  contents: [
    {
      heading: "Ms Malini. A",
      value: [
        "Principal",
        <>
          <a href="mailto://principal@avanipublicschool.com">
            principal@avanipublicschool.com
          </a>
        </>,
        <>
          <a href="tel://919092845000">9092845000</a>
        </>,
      ],
    },
    {
      heading: "Ms S. Jayashri",
      value: ["Vice Principal"],
    },
    {
      heading: "Ms S.Vallikannu",
      value: ["Facilitator"],
    },
  ],
};

export default function SchoolInformation() {
  return (
    <>
      <div className="container text-center" id="school-info">
        <h3 className="text-primary">Avani Public School</h3>
        <div>
          Affiliated to CBSE New Delhi. Affiliation No. <span className="text-primary text-bold">1931552</span>
        </div>
        <div>
          School No. <span className="text-primary text-bold">56669</span>
        </div>
        <p>
          Name of the school with address with pin code
          <br />
          (as per affiliation sanction letter)
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "30px 0",
          }}
          className="school-info-holder"
        >
          <div className="school-info info-block-sm">
            <FiNavigation2 className="icon icon-primary-feather" />
            <p>
              457, Thudiyalur- Kanuvai Road <br />
              Panninadai Post <br />
              Coimbatore -641017 <br />
            </p>
          </div>
          <div className="school-info info-block-sm">
            <FiMessageCircle className="icon icon-primary" />
            <p>
              <a href="mailto://info@avanipublicschool.com">
                info@avanipublicschool.com
              </a>{" "}
              <br />
              <a href="mailto://principal@avanipublicschool.com">
                principal@avanipublicschool.com
              </a>
            </p>
          </div>
          <div className="school-info info-block-sm">
            <FiPhoneCall className="icon icon-primary" />
            <p>
              <a href="tel://04222909545">0422-2909545</a> <br />
              <a href="tel://919092845000">90928 45000</a>
            </p>
          </div>
        </div>
      </div>
      <div
        className="d-flex d-flex-row overflow-hidden pos-relative h-100 row-reverse"
        style={{ paddingTop: 0 }}
      >
        <div className="secondary-block info-block">
          <div className="block-content-padding">
            <p>Year of the establishment of the school</p>
            <h2 className="text-primary year-est">2016</h2>
          </div>
        </div>
      </div>
      <div className="container text-center">
        <p style={{ lineHeight: "42px", fontWeight: "bold", fontSize: "24px" }}>
          Whether NOC From state obtained
        </p>
        <p className="ft-32 text-primary">
          Yes, NOC from Tamilnadu state is obtained
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "30px 0",
          }}
          className="school-info-holder"
        >
          <div className="school-info info-block-sm">
            <h3>NOC No</h3>
            <p className="text-primary font-bold">K.Dis.No.005842/G1-S1/2020</p>
          </div>
          <div className="school-info info-block-sm">
            <h3>NOC issuing date</h3>
            <p className="text-primary">11/03/2020</p>
          </div>
        </div>
      </div>
      <SchoolBlock
        heading="Is the school recognized, if yes by which authority"
        response="YES by State Government"
      />
      <div className="container text-center">
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "30px 0px",
            flexDirection: "column",
          }}
          className="school-info-holder"
        >
          <h3>Affiliation</h3>
          <div className="school-info">
            <h4>PERMANENT/REGULAR PROVISIONAL</h4>
            <p className="text-primary font-bold">
              Provisional up to middle school
            </p>
          </div>
        </div>
      </div>
      <SchoolBlock
        heading={
          <>
            Name of the Trust / Society / Company Registered under Section 25 of
            the <br />
            Companies Act 1956. Period up to which registration of Trust /
            Society is valid
          </>
        }
        response="V.M.Educational Trust"
      />
      <div
        className="d-flex d-flex-row overflow-hidden pos-relative h-100 row-reverse"
        style={{ paddingTop: 0 }}
      >
        <div className="secondary-block info-block">
          <div className="block-content-padding">
            <p>Name of the Correspondent </p>
            <h2 className="text-primary year-founder">K. Vijayakumar</h2>
          </div>
        </div>
      </div>
      {[
        area,
        facilities,
        transport,
        faculty,
        payment,
        library,
        greivance,
        harrasment,
      ].map((prop, key) => (
        <RenderTable tableInfo={prop} key={key} />
      ))}
      <MandatoryDisclosure />
    </>
  );
}

function SchoolBlock({ heading, response }) {
  return (
    <div
      className="d-flex d-flex-row two-columns overflow-hidden pos-relative h-100 vision-block"
      style={{ paddingTop: 0 }}
      id="vision"
    >
      <div className="bg-red  info-block">
        <div className="block-content-padding">
          <p
            className="color-white"
            style={{ lineHeight: "42px", fontSize: "24px" }}
          >
            {heading}
          </p>
          <p
            className="color-white"
            style={{
              marginTop: "40px",
              fontWeight: "bold",
              lineHeight: "42px",
            }}
          >
            {response}
          </p>
        </div>
      </div>
    </div>
  );
}

function RenderTable({ tableInfo }) {
  return (
    <div className="container text-center school-table">
      <h3>{tableInfo.heading}</h3>
      <table className="table table-striped">
        {tableInfo && tableInfo.header && (
          <>
            <thead>
              {tableInfo.header.map((prop, key) => {
                return <th>{prop.title}</th>;
              })}
            </thead>
          </>
        )}
        {!tableInfo.threeRow &&
          tableInfo.contents.map((prop, key) => {
            return (
              <tr>
                <td className="heading">{prop.heading}</td>
                <td>{prop.value}</td>
              </tr>
            );
          })}
        {tableInfo.threeRow &&
          tableInfo.contents.map((prop, key) => {
            return (
              <tr>
                <td className="heading">{prop.heading}</td>
                {prop.value.map((val) =>
                  !val.isTotal ? (
                    <td>{val}</td>
                  ) : (
                    <>
                      {val.isTotal && (
                        <td>
                          <table>
                            <tr>
                              <td style={{ textAlign: "center" }}>
                                {val.value}
                              </td>
                            </tr>
                          </table>
                        </td>
                      )}
                    </>
                  )
                )}
              </tr>
            );
          })}
      </table>
    </div>
  );
}
