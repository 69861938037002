import { RenderIconPara } from "../../../common/Common";
import "./tour.css";
import { FiCheck } from "react-icons/fi";

export default function Tour() {
  return (
    <>
      <div className="d-flex d-flex-row two-columns overflow-hidden h-100">
        <div className="column">
          <h2>Take a Tour</h2>
          <div className="seperator" />
          <div className="desk-content tour-content">
            <p className="title-para sub-para">
              Prioritizing education parallel with emotional, physical as well
              as social sensitivity is a mandate. Avani delivers this with its
            </p>
          </div>
          <div className="desk-content tour-content tour-content-list">
            <RenderIconPara
              icon={<FiCheck className="icon icon-primary-feather" />}
              content={"Well Planned and aesthetic infrastructure"}
              paraClass={"contact-icon-info para-class"}
            />
            <RenderIconPara
              icon={<FiCheck className="icon icon-primary-feather" />}
              content={"Aerate Spacious Well Equipped Classrooms"}
              paraClass={"contact-icon-info para-class"}
            />
            <RenderIconPara
              icon={<FiCheck className="icon icon-primary-feather" />}
              content={"Well Organized, safe storage and easy access library"}
              paraClass={"contact-icon-info para-class"}
            />
            <RenderIconPara
              icon={<FiCheck className="icon icon-primary-feather" />}
              content={
                "Well Provisioned sports infra to bring out the best talent"
              }
              paraClass={"contact-icon-info para-class"}
            />
          </div>
        </div>
        <div className="column">
          <div className="eltdf-video-button-image pos-relative">
            <img
              src="https://res.cloudinary.com/du0fuqphz/image/upload/v1639455888/avani-public/images/home-image-4.jpg"
              className="desk-image"
              alt="a"
              style={{height:480}}
            />{" "}
            <div className="play-btn">
              {/* <img
                width="96"
                height="96"
                src="https://academist.qodeinteractive.com/wp-content/uploads/2018/07/Video-button.png"
                className="attachment-full size-full"
                alt="a"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
