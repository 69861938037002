import TabsList from "./Tabs/Tabs";
import Tour from "./Tour/Tour";
import { useCallback, useState } from "react";
import Facility from "./Facility/Facility";

export default function Campus() {
  const [CampusDetail, setCampusDetail] = useState("tour");
  const callback = useCallback((CampusDetail) => {
    setCampusDetail(CampusDetail);
  }, []);

  return (
    <div className="container" id="campus">
      <div className="d-flex d-flex-row two-columns h-100">
        <div className="column">
          <h2>Campus</h2>          
        </div>
        <div className="column">
          <TabsList parentCallback = {callback} CampusDetail={CampusDetail} />         
        </div>
      </div>
      <>
      {
        (() => {
          switch (CampusDetail) {
            case "tour":
              return <Tour />
            case "gallery":
              return <Facility />
          }
        }) ()
      }
      </>
    </div>
  );
}
