import "./DeskLetter.css";

export default function DeskLetter({Title, Content, img, link}) {
  return (
    <>
      <div className="container" id={link}>
        <div className="d-flex d-flex-row two-columns h-100">
          <div className="column column-3">
            <h2>{Title}</h2>
            <div className="seperator" />
            <div className="desk-content">
              <Content />
            </div>
          </div>
          <div className="column column-1" style={{marginBottom: 30}}>
            <img src={img} alt={Title} className="desk-image" />
          </div>
        </div>
      </div>
    </>
  );
}
