import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import { ParallaxProvider } from "react-scroll-parallax";

import AppLayout from "./layout/AppLayout";
import ScrollToTop from "./components/navbar/scrollTop";

function App() {
  return (

    <Router>
      <ScrollToTop />
      <ParallaxProvider>
          <Switch>
            <Route path="/"  render={(props) => <AppLayout {...props} /> } />
          </Switch>
        </ParallaxProvider>
    </Router>
  );
}

export default App;
