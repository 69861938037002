import HeaderContent from "../header/header.content";
import "./AcademicsComponent.css";

export default function AcademicsComponent() {
  return (
    <>
      <div className="content-wrapper">
        <div className="container">
          <div className="d-flex d-flex-row two-columns">
            <div className="column">
              <h2>CBSE program at Avani Public school</h2>
              <p>
                At <b> "Avani public school" </b> the CBSE program is designed
                from Grade 1, keeping in mind the multidimensional child-centric
                approach. It is both interactive and pragmatic. We nurture our
                children to be confident and self-sufficient.
              </p>
              <h3>Montessori education at Avani Public school</h3>
              <p>
                Each Montessori environment at Avani encompasses the mixed age
                group of children between 3 to 6 years and contains work
                materials that help in the refinement of senses. Carefully
                designed materials and activities in our Montessori environment
                stimulate independent exploration. The child progresses at his
                or her own pace within an individualized learning framework,
                moving from simple to complex activities. The child strives to
                achieve perfection and begins to explore the world around them.
                The child develops concentration, self-discipline, independence
                and respect for others.
              </p>
              <h3>GRADES</h3>
              <p>
                We are working towards an ambitious vision. Here, education
                fosters profound and manifold cooperation among our member
                organisations and connects them to a new window for teamwork.
                Our members' mastery promotes the adoption of progressive
                education practices a more holistic approach that focuses on
                individual students' needs and self-control with respect to the
                learning space in experiential, digital learning escort and
                educational content and materials to assistance such as teacher
                training, pedagogical and vocational programs, as well as
                multi-purpose solutions in the physical and innovative
                atmosphere
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
