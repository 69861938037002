import './nav-styles.css'; 

export default function Logo (){
    return (
        <div className="logo-holder">
          <img
            src="/avani-logo-footer.png"
            width="170"
            height="30"
            alt="logo"
          />
        </div>
    )
}