import Swiper from "react-id-swiper";
import "swiper/swiper-bundle.min.css";
import "./teacher.css";
import { TeacherDetails } from "./Teachers";
import { useRef, useState } from "react";
import LetteredAvatar from "react-lettered-avatar";

const arrayWithColors = [
  "#2ecc71",
  "#3498db",
  "#8e44ad",
  "#e67e22",
  "#e74c3c",
  "#1abc9c",
  "#2c3e50",
];

export default function Teacher() {
  const params = {
    slidesPerView: 4,
    spaceBetween: 10,
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  };
  const ref = useRef();

  const [activeIndex, setactiveIndex] = useState(0);

  const goNext = () => {
    if (ref.current && ref.current.swiper) {
      ref.current.swiper.slideNext();
      setactiveIndex(ref.current.swiper.realIndex);
    }
  };

  const goPrev = () => {
    if (ref.current && ref.current.swiper) {
      ref.current.swiper.slidePrev();
      setactiveIndex(ref.current.swiper.realIndex);
    }
  };

  return (
    <>
      <div className="d-flex d-flex-row two-columns overflow-hidden h-100">
        <Swiper {...params} ref={ref}>
          {TeacherDetails.map((prop, key) => {
            return (
              <div>
                <Slide {...prop} />
              </div>
            );
          })}
        </Swiper>
        <div
          className="swiper-button-next"
          tabindex="0"
          role="button"
          aria-label="Next slide"
          aria-disabled="false"
          onClick={goNext}
          style={{ opacity: activeIndex === 18 ? 0.35 : 1 }}
        ></div>
        <div
          className="swiper-button-prev"
          tabindex="0"
          role="button"
          aria-label="Previous slide"
          aria-disabled="false"
          onClick={goPrev}
          style={{ opacity: activeIndex === 0 ? 0.35 : 1 }}
        ></div>
      </div>
    </>
  );
}

const Slide = ({ img, name, designation, qualification }) => {
  return (
    <div className="d-flex d-flex-column justify-center slide teacher-slide">
      <LetteredAvatar
        name={name.replace("Ms", "")}
        backgroundColors={arrayWithColors}
      />
      <div>
        <h3>{name}</h3>
        <p>{qualification}</p>
        <p>{designation}</p>
      </div>
    </div>
  );
};
