import "./management.css";
import { SeniorManagementTeam, SMC } from "../Campus/Teacher/Teachers";

export default function SchoolManagement() {
  return (
    <>
      <div className="container" id="smc">
        <div className="d-flex d-flex-row two-columns h-100">
          <div className="column column-1">
            <h2>SMC</h2>
            <div className="seperator" />
            <div className="desk-content">
            </div>
          </div>
        </div>
        <ManagementTeam />
      </div>
    </>
  );
}

const ManagementTeam = () => {
  return (
    <div className="grid-container-smc grid-margin team-grid">
      {SMC.map((prop, key) => {
        return (
          <div className="column">
            <ManagmementMember {...prop}  />
          </div>
        );
      })}
    </div>
  );
};


export const ManagmementMember = ({ name, designation, address }) => {
  return (
    <div className="d-flex d-flex-row">
      <div className="column profile-description" style={{ flex:1 }}>
        <h4>{name}</h4>
        <p className="role">{designation}</p>
        <p>{address}</p>
      </div>
    </div>
  );
};
