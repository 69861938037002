export const Teachers = [
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/educator-img-3.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/educator-img-2.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/educator-img-1.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/07/Instructor-slider-img.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/educator-img-4.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/educator-img-3.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/educator-img-2.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/educator-img-1.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/07/Instructor-slider-img.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/educator-img-4.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/educator-img-3.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/educator-img-2.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/educator-img-1.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/main-home-team-big-1.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/main-home-team-big-4.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/main-home-team-big-2.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/main-home-team-big-5.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/main-home-team-big-3.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/main-home-team-big-6.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/main-home-team-big-7.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/main-home-team-big-8.jpg",
  "https://academist.qodeinteractive.com/wp-content/uploads/2018/06/main-home-team-big-9.jpg",
];


export const TeacherDetails = [
   {
    name: 'Ms N.Sanghamithra Jayakrishna',
    designation: 'Secretary',
    qualification: 'B.Tech., MBA., Diploma in Montessori., B.Ed.,'
  },
  {
    name: 'Ms Malini A',
    designation: 'Principal',
    qualification: 'M.A., B.Ed.,'
  },
  {
    name: 'Ms Jayasri.S',
    designation: 'Coordinator',
    qualification: ' MCA.,Diploma in Montessori, B.Ed.,'
  },
  {
    "name": "Ms V.Rajalakshmi",
    "qualification": "B.Sc.,B.Ed.,Dip in Special Educator",
    "designation": "Special Educator"
  },
  {
    "name": "Ms Nagalakshmi. H",
    "qualification": "M.Com., Dip in Montessori.,B.Ed.,",
    "designation": "NTT"
  },
  {
    "name": "Ms Lavanya Narasimhan",
    "qualification": "B.Sc.,MCA.,Dip in Montessori.,B.Ed.,",
    "designation": "NTT"
  },
  {
    "name": "Ms Mary sharmila usha. s",
    "qualification": "B.Com.,DMCE",
    "designation": "NTT"
  },
  {
    "name": "Ms Anitha.G",
    "qualification": "B.A",
    "designation": "NTT"
  },
  {
    "name": "Ms Kiruthika.B",
    "qualification": "B.Sc.,Dip in Montessori.,B.Ed.,",
    "designation": "NTT"
  },
  {
    name: 'Ms R. Mredhula ',
    designation: 'NTT',
    qualification: 'B.Com.,M.A.,B.Ed.,'
  },
  {
    "name": "Ms Revathi V",
    "qualification": "BCS.,M.Com",
    "designation": "NTT"
  },
  {
    "name": "Ms Bincy Binu",
    "qualification": "MSc Biotechnology, Diploma in Montessori  and child education",
    "designation": "NTT"
  },
  {
    "name": "Ms Divya N",
    "qualification": "M.Sc.,Dip in Montessori.,B.Ed.,",
    "designation": "NTT"
  },
  {
    "name": "Ms Sunitha.Y",
    "qualification": "M.Sc..,",
    "designation": "NTT"
  },
  {
    "name": "Ms Kaveri N",
    "qualification": "B.Sc..,",
    "designation": "NTT"
  },
  {
    "name": "Pavithra",
    "qualification": "B.Sc..,",
    "designation": "NTT"
  },
  {
    "name": "Sakthi",
    "qualification": "M.Sc..,",
    "designation": "NTT"
  },
  {
    "name": "Vanitha D",
    "qualification": "M.E.,Dip in Montessori",
    "designation": "NTT"
  },
  {
    "name": "Libya R",
    "qualification": "B.A.,Dip in Teachers Program.,",
    "designation": "NTT"
  },
  {
    "name": "Sathya",
    "qualification": "B.Sc.,Dip in Early Childhood",
    "designation": "NTT"
  },
  {
    "name": "Sri Devi J",
    "qualification": "M.A(Yoga)., MBA",
    "designation": "NTT"
  },
  {
    "name": "Jigna Nishit Nagda",
    "qualification": "Bachelors in Commerce",
    "designation": "NTT"
  },
  {
    "name": "Malini G ",
    "qualification": "MSc Software Engineering , Early Childhood Credential in Montessori",
    "designation": "NTT"
  },
  {
    name: 'Divya S',
    designation: 'PRT',
    qualification: 'B.Sc.,B.Ed.,'
  },
  {
    name: 'Tamilselvi S',
    designation: 'PRT',
    qualification: 'B.E.,B.Ed.,,'
  },
  {
    name: 'Neethu Bejoy',
    designation: 'PRT',
    qualification: 'B.Sc., B.Ed , M.Sc'
  },
  {
    name: 'Lidwin Christina Dorathy A',
    designation: 'PRT',
    qualification: 'MSc.., B.Ed.,'
  },
  {
    name: 'Subashree R',
    designation: 'PRT',
    qualification: 'M.Com.,B.Ed.,'
  },
  {
    name: 'Divya Nagulan',
    designation: 'PRT',
    qualification: 'M.A.,B.Sc.,B.Ed.,'
  },
  {
    name: 'Parvathavarthini R',
    designation: 'PRT',
    qualification: 'B.E'
  },
  {
    name: 'Duncy Jitto',
    designation: 'PRT',
    qualification: 'MSc., B.Ed.,'
  },
  {
    name: 'Saradhamani C',
    designation: 'PRT',
    qualification: 'D.T.Ed.,B.Lit.,B.Ed'
  },
  {
    name: 'Showmiya R',
    designation: 'PRT',
    qualification: 'M.Sc., B.Ed.,'
  },
  {
    name: 'Lakshmi Priya',
    designation: 'PRT',
    qualification: 'M.Sc.,'
  },
  {
    name: 'Tasnim',
    designation: 'PRT',
    qualification: 'BCS'
  },
  {
    name: 'Ruby Cherian',
    designation: 'PRT',
    qualification: 'B.A B.Ed,'
  },
  {
    name: 'Kashmir Arokya Deepa',
    designation: 'TGT',
    qualification: 'M.sc M.Phil.,B.Ed PGDOR'
  },
  {
    name: 'Radhika Murali',
    designation: 'TGT',
    qualification: 'M.Sc.,B.Ed'
  },
  {
    name: 'AgathaChristy',
    designation: 'TGT',
    qualification: 'M.A.,D.T.Ed.,M.Ed'
  },
  {
    name: 'Poonam Kapoor',
    designation: 'TGT',
    qualification: 'B.A.,'
  },
  {
    name: 'Vallikannu S',
    designation: 'TGT',
    qualification: 'M.Sc.,M.Phil.,B.Ed.,'
  },
  {
    name: 'Mathangi Prasad',
    designation: 'PRT',
    qualification: 'B.Com.,Dip in Teachers Program.,'
  },
  {
    name: 'Divyashree ',
    designation: 'TGT',
    qualification: 'B.Com (CS)'
  },
  {
    name: 'Bharathy P',
    designation: 'TGT',
    qualification: 'M.Sc., Dip in Montessori.,B.Ed'
  },
  {
    "name": "Kalpana S",
    "qualification": "M.A.,B.Ed",
    "designation": "TGT"
  },
  {
    name: 'Sasikala ',
    designation: 'PET',
    qualification: 'B.A.,B.Ped.,'
  },
  {
    name: 'Manoj Kumar H',
    designation: 'PET',
    qualification: 'M.Ped'
  },
  {
    name: 'Prarthana.s',
    designation: 'PRT',
    qualification: 'M.Sc.,B.Ed.,'
  },
  {
    name: 'Arthi Deepu',
    designation: 'PRT',
    qualification: 'M.A.,B.Ed.,'
  },
  {
    name: 'Anushya R',
    designation: 'PRT',
    qualification: 'M.Sc.,B.Ed'
  },
  {
    name: 'Bhuvaneshwari S',
    designation: 'PRT',
    qualification: 'B.Sc.,B.Ed'
  },
  {
    name: 'Nandhini',
    designation: 'NTT',
    qualification: 'BBM.,MBA'
  },
  {
    name: 'Silpa',
    designation: 'TGT',
    qualification: 'M.A.,B.Ed'
  },
]

export const MontessoriTeachers = [
  
]

export const SMC = [
  {
    name: 'Ms N.Sanghamithra',
    designation: 'Member',
    address: `
    Avani Public School, 457, Kanuvai Thudiyalur Road, Pannimadai Post, Coimbatore 
- 641 017.`
  },
  {
    name: 'Ms Malini .A',
    designation: 'Member Secretary',
    address: `
    Principal, Avani Public School, 457, Kanuvai Thudiyalur Road, Pannimadai Post, Coimbatore 
- 641 017.`
  },
  {
    name: 'Ms Jayasri .A',
    designation: 'Member',
    address: `
    Vice Principal, Avani Public School, 457, Kanuvai Thudiyalur Road, Pannimadai Post, Coimbatore 
- 641 017.`
  },
  {
    name: 'Dr P.S.Martin',
    designation: 'Recommended by the Trust to be nominated by the Board',
    address: `
    Principal, Suguna Pip School,Kalapatti Main Road, Adjacent to Suguna Polytechnic ,GKD Nagar,Nehru Nagar West,Coimbatore Tamil Nadu.
    `
  },
  {
    name: 'Mr Shiva Kumar',
    designation: 'Member',
    address: `
    Principal,
    Aksharam International School
    Vinayaka Temple, SF 426/2, Kuttai Thottam Near Mettupalayam, Mettupalayam Rd, Coimbatore, Tamil Nadu 641019
    `
  },
  {
    name: 'Dr C Lakshmi',
    designation: 'Member',
    address: `
    Doctor
    `
  },
  {
    name: 'Mr P Rangaraj',
    designation: 'Member',
    address: `
    Lawyer
    `
  },
  {
    name: 'Mr V Satish Balaji',
    designation: 'Member',
    address: `
    Managing Trustee of an
    NGO, Coordinator for CERT, Managing Committee Member in RAAC, Active Member in C4TN, Swachh Bharath Ambassador
    `
  },
  {
    name: 'Ms V. Rajalakshmi',
    designation: 'Member',
    address: `
    Coordinator,
    Avani Public
    School,457,Thudiyalur -
    Kanuvai Road ,Pannimadai
    Post,Coimbatore - 641 017.
    `
  },
  {
    name: 'Ms Divya N',
    designation: 'Member',
    address: `
    Primary Coordinator
    Avani Public
    School,457,Thudiyalur -
    Kanuvai Road ,Pannimadai
    Post,Coimbatore - 641 017
    `
  },
  {
    name: 'Ms Divya S',
    designation: 'Member',
    address: `
    Primary Coordinator
    Avani Public
    School,457,Thudiyalur -
    Kanuvai Road ,Pannimadai
    Post,Coimbatore - 641 017
    `
  },
  {
    name: 'Ms Agatha Christy',
    designation: 'Member',
    address: `
    Middle School Coordinator
    Avani Public
    School,457,Thudiyalur -
    Kanuvai Road ,Pannimadai
    Post,Coimbatore - 641 017
    `
  },
]

export const SeniorManagementTeam = [
  {
    name: 'Mr.K.Vijayakumar',
    designation: 'Chairman'
  },
  {
    name: 'Mrs.N.Sangamithra Jayakrishna',
    designation: 'Secretary'
  },
  {
    name: 'V.Jayakrishna',
    designation: 'Trustee'
  },
  {
    name: 'Mrs.Maragatham',
    designation: 'Trustee'
  },
]
