import { Disclosure, Documents } from "./disclosures";
import PdfPreview from "./PdfPreview";
import './PdfPreview.css'
export default function MandatoryDisclosure() {
  return (
    <>
    <div className="container text-center" id="disclosure" >
        <h3>Documents and Information</h3>
        <a href="https://res.cloudinary.com/du0fuqphz/image/upload/v1713022249/avani-public/2024/Mandatory_Disclosure_Details___SARAS_5.0.pdf" target="_blank" className="disclosure-link"><h4>Mandatory Disclosure Form</h4></a>
        <table className="table class-table school-table" style={{ width: '100%' }}>
          <tr>
            <th colSpan={1}>#</th>
            <th colSpan={7} style={{ textAlign: 'left' }}>Document Information</th>
            <th colSpan={3}>Upload Documents</th>
          </tr>
          {
            Documents.map(({id, name, url}, key) => (
              <tr>
                <td colSpan={1}>{id}</td>
                <td colSpan={7} style={{textAlign: 'left'}}>{name}</td>
                <td colSpan={3}> {url && <a href={url} target="_blank">View</a>}</td>
              </tr>
            ))
          }
        </table>
      </div>
    
      <div className="container" style={{marginBottom: 30}}>
        <h1>Disclosure</h1>
        <div className="seperator" />
        <div className="grid-container-4 grid-row-gap-sm">
          {Disclosure.map((prop, key) => {
            return <PdfPreview {...prop} key={`PDf-preview-${key}`} />;
          })}
        </div>
      </div>
    </>
  );
}
