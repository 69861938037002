import Facility from "../about/Campus/Facility/Facility";
import Tour from "../about/Campus/Tour/Tour";

export default function FacilitiesViewComponent() {
  return (
    <>
      <div className="container" id="campus">
        <div className="d-flex d-flex-row two-columns h-100">
          <Tour />
        </div>
        <div className="container" id="facilities">
          <h1>Facilities</h1>
          <div className="seperator" />
          <div class="d-flex d-flex-row two-columns overflow-hidden h-100">
            <Facility />
          </div>
        </div>
      </div>
    </>
  );
}
