import { useRef } from "react";
import { Link } from "react-router-dom";
import GoogleMapRender from "../../maps/map";


export default function Info() {
  const ref = useRef();
  return (
    <div className="d-flex d-flex-row two-columns">
      <div className="column">
        <div className="d-flex d-flex-row two-columns h-100">
          <div className="column">
            <SideContent />
          </div>
          <div className="column">
            <img
              width="1024"
              height="1050"
              src="https://res.cloudinary.com/du0fuqphz/image/upload/v1639455888/avani-public/images/home-image-4.jpg"
              class="attachment-full size-full"
              alt="a"
            />
          </div>
        </div>
      </div>
      <div className="column" id="map" ref={ref}>
        <GoogleMapRender />
      </div>
    </div>
  );
}

function SideContent() {
  return (
    <div className="homepage-about-content about-content-padding">
      
      <div>
        <h3 className="heading-title">
          <span className="eltdf-iwt-title-text">Admissions</span>
        </h3>
        <p className="about-content-para">
          You can now apply online and our team will contact you and help you complete the process. 
        </p>
        <Link
          to="/admissions"
          className="btn btn-simple"
        >
          <span className="underline-link">Find More</span>
        </Link>
      </div>
    </div>
  );
}

