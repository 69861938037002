export const galleryImages = [
    {
      original: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122429/avani-public/gallery/4.png',
      thumbnail: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122429/avani-public/gallery/4.png',
    },
    {
      original: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122426/avani-public/gallery/20220812_103126.jpg',
      thumbnail: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122426/avani-public/gallery/20220812_103126.jpg',
    },
    {
      original: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122392/avani-public/gallery/1660307259761.jpg',
      thumbnail: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122392/avani-public/gallery/1660307259761.jpg',
    },
      {
        original: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679123513/avani-public/gallery/BAK07594.jpg',
        thumbnail: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679123513/avani-public/gallery/BAK07594.jpg',
      },
      {
        original: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122924/avani-public/gallery/BAK07788.jpg',
        thumbnail: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122924/avani-public/gallery/BAK07788.jpg',
      },
      {
        original: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122433/avani-public/gallery/IMG_4134.jpg',
        thumbnail: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122433/avani-public/gallery/IMG_4134.jpg',
      },
      {
        original: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122379/avani-public/gallery/IMG_20220722_105624.jpg',
        thumbnail: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122379/avani-public/gallery/IMG_20220722_105624.jpg',
      },
      {
        original: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122391/avani-public/gallery/IMG_20221118_122309.jpg',
        thumbnail: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122391/avani-public/gallery/IMG_20221118_122309.jpg',
      },
      {
        original: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122376/avani-public/gallery/IMG-20220908-WA0023.jpg',
        thumbnail: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122376/avani-public/gallery/IMG-20220908-WA0023.jpg',
      },
      {
        original: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122429/avani-public/gallery/SS__0201.jpg',
        thumbnail: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1679122429/avani-public/gallery/SS__0201.jpg',
      },
  ];