import React from "react";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import "./PdfPreview.css";
import { FiCheck, FiFeather } from "react-icons/fi";
export default function PdfPreview({ name, url, Icon }) {
  return (
    <React.Fragment>
     <a className="pdf-preview-container gallery-item" href={url} target="_blank">
          <div className="pdf-container-wrapper">
            <div className="pdf-info">
              <div className="pdf-info-content">
                <div>
                  <Icon  />
                </div>
                <div className="file-info">
                    <span className="title-content">{name}</span>
                </div>
              </div>
            </div>
          </div>
        </a>
    </React.Fragment>
  );
}
