import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import Image from "../../../images/Image";
import { galleryImages } from "./galleryItems";
import "./gallery.css";
import GalleryModal from "./GalleryModal";
import { useState } from "react";

export default function Gallery() {
  const [showModal, setShowModal] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  const onImageClick = (key) => {
      setActiveSlide(key);
      setShowModal(true);
  };

  const onClose = () => {
      setShowModal(false);
  }
  const RenderGallery = () =>
    galleryImages.map((prop, key) => {
      return (
        <div className="gallery-item" onClick={onImageClick.bind(this, key)}>
          <Image src={prop.thumbnail} alt={prop.thumbnail} key={key} />
        </div>
      );
    });
  return (
    <>
      <div className="container" id="gallery">
        <h1>Gallery</h1>
        <div className="seperator" />

        <div className="grid-container-3 grid-container-4">
          <RenderGallery />
        </div>
      </div>
      {
          showModal && 
          <GalleryModal show={showModal} onClose={onClose} activeSlide={activeSlide} />
      }
    </>
  );
}
