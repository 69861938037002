
import FirstBanner from './banner/Banner';
import SecondBanner from './banner/SecondBanner';
import './homepage.css'; 
import {useState, useEffect} from 'react';
import Info from './Info/Info';
import ContactHomePage from './contact/contact';

export default function HomePage(){
    const[height, setHeight] = useState(window.innerHeight - 75); 

    return (
      <>
         <div className="d-flex d-flex-row full-width space-between two-columns">
           <FirstBanner height={height} />
           <SecondBanner height={height} />
       </div>
        <Info />
        <ContactHomePage />
      </>

    )
}