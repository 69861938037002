import { useEffect } from "react";

export default function Calendar({ title }) {
  useEffect(() => {
    let script = document.createElement("script");
    script.src =
      "//static.fliphtml5.com/web/js/plugin/LightBox/js/fliphtml5-light-box-api-min.js";
    script.async = true;

    document.body.appendChild(script);

    return () => document.body.removeChild(script)
  }, []);
  return (
    <>
      <h3>Academic Calendar</h3>
      <img
        src="https://online.fliphtml5.com/mlxqa/nnpt/files/shot.jpg"
        data-rel="fh5-light-box-demo"
        data-href="https://online.fliphtml5.com/mlxqa/nnpt/"
        data-width="700"
        data-height="400"
        data-title="grade calendar"
        style={{width: '100%'}}
      />
    </>
  );
}
