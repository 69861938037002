import { useRef } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import '../homepage.css';
import Swiper from "react-id-swiper";
import "swiper/swiper-bundle.min.css";
import { Facilities } from "../../about/Campus/Facility/Facilities";

export default function TestimonialSlider({ height }) {
  const params = {
    slidesPerView: 1,
    loop: true,
    autoplay: true,
  };
  const ref = useRef();
  const goNext = () => {
    const swiper = ref.current.swiper; 
    swiper.slideNext(); 
  }

  const goPrev = () => {
    const swiper = ref.current.swiper; 
    swiper.slidePrev(); 
  }

  return (
    <>
      <Swiper {...params} ref={ref}>
          {Facilities.map((prop, key) => {
            return (
              <div>
                <Slide img={prop.url} height={height} facility={prop} />
              </div>
            );
          })}
        </Swiper>
      <div className="control">
        <IoIosArrowBack className="control-icons" onClick={goPrev}/>
        <IoIosArrowForward className="control-icons" onClick={goNext}/>
      </div>
    </>
  );
}

function Slide({ height, img, facility }) {
  return (
    <>
      <div
        className="d-flex d-flex-row two-columns hidden-overflow pos-relative testimonial-slider"
        style={{ height: height }}
      >
        <div className="column slider-img">
          <img src={img} />
        </div>
        <div className="column bg-red testimonial-content clear">
          <div
            className="testimonial-content-block d-flex d-flex-column justify-center"
            style={{ height: height }}
          >
            <h3 className="testimonial-title slider-text">
              {facility.shortText}
            </h3>
            <h4 class="testimonial-author slider-text">
              <span>{facility.name}</span>
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}
