import "./ContentHeader.css";

export default function HeaderContent({content}) {
  return (
    <div className="header-content plain-bg d-flex d-flex-column justify-content">
      <div className="container no-padding-top">
        <h2>{content}</h2>
      </div>
    </div>
  );
}
