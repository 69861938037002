import "./admission-styles.css";
import AdmissionParallax from "../contactpage/admissionparallax";
import Campus from "../about/Campus/Campus";
import { RenderIconPara } from "../common/Common";
import { FiCheck } from "react-icons/fi";

export default function AdmissionPage() {
  return (
    <>
    <div className="content-wrapper">
        <Campus />
        <Criteria />
      </div>
      <AdmissionParallax />
    </>
  );
}

const Criteria = () => {
  return (
    <div className="container" id="criteria">
      <div className="d-flex d-flex-row two-columns overflow-hidden h-100">
        <div className="column">
          <h2>Criteria</h2>
          <div className="seperator" />
          <div className="desk-content tour-content">
            <p className="title-para sub-para">
              Our admission process is carefully crafted to bring out the best
              out of students and to assess their potential.
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex d-flex-row">
        <div className="column">
          <h4 style={{ marginBottom: 0 }}>Admission Procedure</h4>
          <div
            className="desk-content tour-content tour-content-list"
            style={{ paddingLeft: 0, marginTop: 10 }}
          >
            <RenderIconPara
              icon={<FiCheck className="icon icon-primary-feather" />}
              content={"Enquiry form"}
              paraClass={"contact-icon-info para-class"}
            />
            <RenderIconPara
              icon={<FiCheck className="icon icon-primary-feather" />}
              content={
                "Verification of the birth certificate for the right grade."
              }
              paraClass={"contact-icon-info para-class"}
            />
            <RenderIconPara
              icon={<FiCheck className="icon icon-primary-feather" />}
              content={"Entrance Test (according to the grades)"}
              paraClass={"contact-icon-info para-class"}
            />
            <RenderIconPara
              icon={<FiCheck className="icon icon-primary-feather" />}
              content={
                "The applicant will be invited to the School and encouraged to meet relevant members of the faculty and administration individually."
              }
              paraClass={"contact-icon-info para-class"}
            />
          </div>
        </div>
        <div className="column">
          <h4>Documents Required</h4>
          <div
            className="desk-content tour-content tour-content-list"
            style={{ paddingLeft: 0, marginTop: 10 }}
          >
            <RenderIconPara
              icon={<FiCheck className="icon icon-primary-feather" />}
              content={"Birth Certificate of the Student - 1 Copy"}
              paraClass={"contact-icon-info para-class"}
            />
            <RenderIconPara
              icon={<FiCheck className="icon icon-primary-feather" />}
              content={"Aadhar card of the Student - 1 Copy"}
              paraClass={"contact-icon-info para-class"}
            />
            <RenderIconPara
              icon={<FiCheck className="icon icon-primary-feather" />}
              content={"Community Certificate -  1 Copy"}
              paraClass={"contact-icon-info para-class"}
            />
            <RenderIconPara
              icon={<FiCheck className="icon icon-primary-feather" />}
              content={"Passport Size Photos of the Student - 8 Nos"}
              paraClass={"contact-icon-info para-class"}
            />
            <RenderIconPara
              icon={<FiCheck className="icon icon-primary-feather" />}
              content={"Passport Size Photos of the Parents  - 4 Nos"}
              paraClass={"contact-icon-info para-class"}
            />
            <RenderIconPara
              icon={<FiCheck className="icon icon-primary-feather" />}
              content={"Contact 9092845000 / 9087645000"}
              paraClass={"contact-icon-info para-class"}
            />
          </div>
        </div>
      </div>
      <div className="d-flex d-flex-row two-columns overflow-hidden h-100">
        <div className="column">
          <h3>Fee Structure 2024 - 2025</h3>
        </div>
      </div>
      <div className="d-flex d-flex-row two-columns">
        <div className="column">
          <div
            className="desk-content" style={{marginTop: 30}}>
            <table className="fee-structure">
              <thead>
                <th></th>
                <th>Tuition Fee</th>
                <th>Misc. Fee & E-lab</th>
                
              </thead>
              <tr>
                <td><b>Pre Mont & Mont1</b></td>
                <td>15,850 / term</td>
                <td>1,000 / term</td>
              
              </tr>
              <tr>
                <td><b>Mont 2 & 3</b></td>
                <td>18,900 / term</td>
                <td>1,500 / term</td>
                
              </tr>
              <tr>
                <td><b>Grade 1 to 2</b></td>
                <td>19,400 / term</td>
                <td>2,000 & 500 / term</td>
                
              </tr>
               <tr>
                <td><b>Grade 3 to 5</b></td>
                <td>19,400 / term</td>
                <td>2,300 & 500 / term</td>
               
              </tr>
            <tr>
                <td><b>Grade 6 & 8</b></td>
                <td>20, 800 / term</td>
                <td>2,500 & 500 / term</td>
               
              </tr>
            </table>
              <span><sup>*</sup><b>Van Fee, Book Fee, Uniform Fee as applicable.</b></span>
          </div>
        </div>
      </div>
    </div>
  );
};
