import './Facility.css';
import Swiper from "react-id-swiper";
import { useEffect, useRef, useState } from "react";
import { Facilities } from './Facilities';
import FacilityDetail from './FacilityDetail';

export default function Facility(){
    const params = {
        slidesPerView: 4,
        spaceBetween: 10,
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 30
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 40
          }
        }
      };
      const ref = useRef();
    
      const [activeIndex, setactiveIndex] = useState(0); 
      const[selectedFacility, setSelectedFacility] = useState(null); 
      const [showModal, setShowModal] = useState(false);

      const onModalClose = () => {
          setShowModal(false);
      }
    
      const goNext = () => {
        if (ref.current && ref.current.swiper) {
          ref.current.swiper.slideNext();
          setactiveIndex(ref.current.swiper.realIndex);
        }
      };
    
      const goPrev = () => {
        if (ref.current && ref.current.swiper) {
          ref.current.swiper.slidePrev();
          setactiveIndex(ref.current.swiper.realIndex);
        }
      };

      const onFacilitySelect = (facility) => {
          setSelectedFacility(facility);
          setShowModal(true);
      }

      useEffect(() => {
            return () => {
                setShowModal(false);
                setSelectedFacility(null);
            }
      }, [])
    
      return (
        <>
          <div className="d-flex d-flex-row two-columns overflow-hidden h-100">
            <Swiper {...params} ref={ref}>
              {Facilities.map((prop, key) => {
                return (
                  <div>
                    <Slide facility={prop} onFacilitySelect={onFacilitySelect}  />
                  </div>
                );
              })}
            </Swiper>
            <div
              className="swiper-button-next facility-btn"
              tabindex="0"
              role="button"
              aria-label="Next slide"
              aria-disabled="false"
              onClick={goNext}
              style={{opacity: activeIndex === 18  ? 0.35 : 1 }} />
            <div
              className="swiper-button-prev facility-btn"
              tabindex="0"
              role="button"
              aria-label="Previous slide"
              aria-disabled="false"
              onClick={goPrev}
              style={{opacity: activeIndex === 0 ? 0.35 : 1 }} />
          </div>
          {
              showModal && 
              <FacilityDetail show={showModal} onClose={onModalClose} facility={selectedFacility} />
          }
        </>
      );
}

const Slide = ({ facility, onFacilitySelect }) => {
    return (
      <div className="d-flex d-flex-column justify-center slide" onClick={() => {onFacilitySelect(facility)}}>
        <img src={facility.url} />
        <div>
          <h3>{facility.name}</h3>
        </div>
      </div>
    );
  };