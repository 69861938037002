import ContentWrapper from "../Wrapper/ContentWrapper";
import Academics from "./academics/Academics";
import Art from "./art/Art";
import Commemorate from "./commemorate/Commemorate";
import Extracurricular from "./Extracurricular/Extracurricular";
import Field from "./field/Field";
import Fun from "./fun/Fun";
import ProgramHeader from "./header/PrgramHeader";
import Rapport from "./rapport/Rapport";
import Skill from "./skill/Skill";
import Sports from "./sports/Sports";

const Program = () => {
  return (
    <>
      <ProgramHeader />
      <ContentWrapper>
        <Art />
        <Academics />
        <Extracurricular />
        <Sports />
        <Skill />
        <Fun />
        <Rapport />
        <Commemorate />
        <Field />
      </ContentWrapper>
    </>
  );
};

export default Program;
