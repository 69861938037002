import "../DeskLetter/DeskLetter.css";

export default function AboutSchool() {
  return (
    <>
      <div className="container" id="about">
        <div className="d-flex d-flex-row two-columns h-100">
          <div className="column column-3">
            <h2>About Avani</h2>
            <div className="seperator" />
            <div className="desk-content">
              <p className="title-para">
                All we care about is “The future of our young generation”. Yes,
                We at Avani Public school believe each kid is different in their
                way. Their talents are unique and need the right landscape to
                bring them out. We believe in “Inside Out” combined with a
                multi-dimensional child-centered holistic approach. With this,
                we work with the kids to identify their hidden talents, explore
                them by giving them the right setting and help them demonstrate
                them. Prioritizing education parallel with emotional, physical
                as well as social sensitivity is a mandate. All this combined
                with a fun-filled learning experience, our students just love
                it.
              </p>

              <p>
                We started our journey in October 2014 as a nursery school
                adopting Montessori Syllabus providing a unique learning
                environment to the kids. We saw them grow and with that, we also
                grew in size and scale. We then scaled up to the CBSE syllabus
                starting from 2016. The learning objectives of CBSE are
                amalgamated in perfect tandem. With improved size and scale, we
                started delivering more budding talents into this world.
              </p>
              <p>
                With state of an art facilities on our campus, we give an
                enhanced learning experience to our students. Some of our
                facilities include Competent Facilitators, Physical & Digital
                library, Mont Environment, Composite Laboratory, transport with
                GPS tracking device for Kid’s safety, Clinic and 360-degree CCTV
                surveillance
              </p>
              <p>
                Training has always been an integral part of Avani’s journey. We
                believe in continued knowledge and skill updates for our
                teachers. They undergo regular rigorous training to stay tuned
                to advancement in teaching techniques and enhance their skill
                set. This helps us to have a better today than yesterday.
                Yesterday was not bad and today is better!
              </p>
            </div>
          </div>
          <div className="column column-1">
            <img src="https://res.cloudinary.com/du0fuqphz/image/upload/v1639455888/avani-public/images/home-image-1.jpg" className="desk-image" />
          </div>
        </div>
      </div>
    </>
  );
}
