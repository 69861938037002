import Modal from "../../../Modal/Modal";
import ImageGallery from 'react-image-gallery';
import { galleryImages } from "./galleryItems";
import { GrClose } from "react-icons/gr";
import { useEffect, useRef } from "react";


export default function GalleryModal({show, onClose, activeSlide}){
    const ref = useRef();
    useEffect(() => {
        if(activeSlide){
            ref.current.slideToIndex(activeSlide);
        }
    }, [activeSlide])
    return (
        <Modal show={show}>
             <ImageGallery items={galleryImages} ref={ref} />;
             <div className="close" onClick={onClose}>
              <div>
                  <GrClose className="icon" />
              </div>
          </div>
        </Modal>
    )
}