import { ParallaxBanner } from "react-scroll-parallax";
import "./contact.css";
import * as emailjs from "emailjs-com";
import { useState } from "react";
import { useForm } from "react-hook-form";

export default function AdmissionParallax() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const onSubmit = (data) => {
    setIsLoading(true);

    const { name, email, subject, message } = data;
    let templateParams = {
      from_name: name,
      to_name: name,
      to_email: email,
      subject: subject ? subject : 'Admission Enquiry',
      message: message ? message : 'Admission Enquiry',
    };
    emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_USER_ID
      )
      .then((isSent) => {
        if (isSent.status === 200) {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        alert("Failed to submt your information. Please try again .. ");
      })
    reset();
  };
  return (
    <ParallaxBanner
      className="your-class"
      layers={[
        {
          image:
            "https://res.cloudinary.com/du0fuqphz/image/upload/v1640066469/avani-public/images/gallery/image18.jpg",
          amount: 0.2,
        },
      ]}
      style={{
        height: "836px",
      }}
    >
      <div className="content-wrapper" id="apply">
        <div className="container admission-form-container">
          <div className="d-flex d-flex-row two-columns hidden-overflow pos-relative h-100">
            <div className="column bg-red form-left">
              <div className="clearClass"></div>
              <h2>
                <span style={{ color: "#ffffff" }}>
                  {" "}
                  Foundation through Montessori and CBSE framework
                </span>
              </h2>
            </div>
            <div className="column form-right">
              <h3>Apply now</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <input
                  type="text"
                  placeholder="Name"
                  className="admission-form-input"
                  name="name"
                  {...register("name", { required: true, maxLength: 80 })}
                />
                <input
                  type="text"
                  placeholder="Email"
                  className="admission-form-input"
                  name="email"
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                />
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="admission-form-input"
                  name="phone"
                  {...register("phone", {
                    required: true,
                    maxLength: 10,
                    type: Number,
                  })}
                />
                {Object.entries(errors).length > 0 &&
                  Object.entries(errors)[0] &&
                  Object.entries(errors)[0][0] && (
                    <p style={{ color: "red" }}>
                      {Object.entries(errors)[0][0]}{" "}
                      {Object.entries(errors)[0][1].type}
                    </p>
                  )}
                {!isLoading && !isSuccess && (
                  <button
                    className="btn btn-primary admission-button"
                    type="submit"
                  >
                    Apply Now
                  </button>
                )}
                {isLoading && (
                  <button className="btn btn-primary admission-button">
                    Sending ...
                  </button>
                )}
              </form>
              {isSuccess && (
                <p style={{ color: "green" }}>
                  Form Submitted Successfully, our team will contact your
                  shortly.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </ParallaxBanner>
  );
}
