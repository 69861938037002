import "./Modal.css";
import classnames from 'classnames';
import { useEffect } from "react";

export default function Modal(props) {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);
  return (
    <div className={classnames('modal', {'is-open': props.show})}>
      <div className="modal-content modal-content-bg-transparent">
        {props.children}
      </div>
    </div>
  );
}
