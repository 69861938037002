import { GrLocation } from "react-icons/gr";
import { useForm } from "react-hook-form";
import { IoMdCall, IoIosTimer } from "react-icons/io";
import GoogleMapRender from "../maps/map";
import "./contact.css";
import AdmissionParallax from "./admissionparallax";
import * as emailjs from "emailjs-com";
import { useState } from "react";
export default function ContactPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const onSubmit = (data) => {
    setIsLoading(true);

    const { name, email, subject, message } = data;
    let templateParams = {
      from_name: name,
      to_name: name,
      to_email: email,
      subject: subject,
      message: message,
    };
    emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_USER_ID
      )
      .then((isSent) => {
        if (isSent.status === 200) {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        alert("Failed to submt your information. Please try again .. ");
      })
    reset();
  };
  return (
    <div id="contact">
      <div className="column" id="map" style={{ height: 510 }}>
        <GoogleMapRender />
      </div>

      <div className="content-wrapper">
        <div className="container">
          <div className="d-flex d-flex-row two-columns h-100">
            <div className="column">
              <h2>Contact Us</h2>
              <div className="seperator"></div>
              <div style={{ height: 10 }}></div>
              <p className="contact-text" style={{ width: "80%" }}>
                Feel Free to post any queries and our team will contact you and
                respond to your queries.
              </p>
              <div style={{ height: 40 }}></div>
              <div className="contact-icon-holder">
                <div className="icon-holder">
                  <GrLocation className="icon icon-primary" />
                </div>
                <p className="contact-icon-info" style={{ color: "black" }}>
                  457, Thudiyalur Pannimadai Road, Coimbatore
                </p>
              </div>
              <div className="contact-icon-holder">
                <div className="icon-holder">
                  <IoMdCall className="icon icon-primary" />
                </div>
                <p className="contact-icon-info" style={{ color: "black" }}>
                  90928 45000
                </p>
              </div>
              <div className="contact-icon-holder">
                <div className="icon-holder">
                  <IoIosTimer className="icon icon-primary" />
                </div>
                <p className="contact-icon-info" style={{ color: "black" }}>
                  Mon - Sat 8.00 - 16.00
                </p>
              </div>
            </div>
            <div className="column contact-form-wrapper">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-inner-wrapper">
                  <div className="form-input-wrapper">
                    <input
                      type="text"
                      placeholder="Name"
                      className="form-input"
                      name="name"
                      {...register("name", { required: true, maxLength: 80 })}
                    />
                  </div>
                  <div className="form-input-wrapper">
                    <input
                      type="text"
                      placeholder="Email"
                      className="form-input"
                      name="email"
                      {...register("email", {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                    />
                  </div>
                </div>
                <div className="form-input-wrapper">
                  <input
                    type="text"
                    placeholder="Subject"
                    className="form-input"
                    name="subject"
                    {...register("subject", { required: true })}
                  />
                </div>
                <div className="form-input-wrapper">
                  <textarea
                    type="text"
                    rows="5"
                    placeholder="Message"
                    className="form-input"
                    name="message"
                    {...register("message", { required: true, maxLength: 120 })}
                  />
                </div>
                <div
                  className="form-input-wrapper"
                  style={{ textTransform: "capitalize" }}
                >
                  {Object.entries(errors).length > 0 &&
                    Object.entries(errors)[0] &&
                    Object.entries(errors)[0][0] && (
                      <small style={{ color: "red" }}>
                        {Object.entries(errors)[0][0]}{" "}
                        {Object.entries(errors)[0][1].type}
                      </small>
                    )}
                </div>
                <div className="form-input-wrapper">
                  {!isLoading && !isSuccess && (
                    <button
                      className="btn btn-primary"
                      type="submit"
                    >
                      Enquire Now
                    </button>
                  )}
                  {isSuccess && (
                    <p style={{ color: "green" }}>
                      Form Submitted Successfully, our team will contact your
                      shortly.
                    </p>
                  )}
                  {isLoading && (
                    <button className="btn btn-primary admission-button">
                      Sending ...
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="admission-from-wrapper">
        <AdmissionParallax />
      </div>
    </div>
  );
}
