import React from "react";
import { RenderIconPara } from "../../common/Common";

import "./Rapport.css";
import { FiCheck } from "react-icons/fi";
import RapportSlider from "./RapportSlider";


export default function Rapport() {
  return (
    <>
      <div className="container">
        <div className="d-flex d-flex-row two-columns h-100">
          <div className="column">
            <h2>Teacher students’ rapport</h2>
            <div className="seperator" />
            <div className="desk-content tour-content tour-content-list">
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"A momentous factor in the overall learning and success of students."}
                paraClass={"contact-icon-info para-class"}
              />
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"It's not the traditional way anymore. Students and teachers are partners in learning now."}
                paraClass={"contact-icon-info para-class"}
              />
               <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"Teachareeing the guiding tool for students’ success."}
                paraClass={"contact-icon-info para-class"}
              />
            </div>
          </div>
        </div>
        
        {/* <RapportSlider /> */}
        
      </div>
    </>
  );
}
