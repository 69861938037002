import React from "react";
import { RenderIconPara } from "../../common/Common";

import "./Academics.css";
import AcademicSlider from "./AcademicsSlider";
import { FiCheck } from "react-icons/fi";


export default function Academics() {
  return (
    <>
      <div className="container">
        <div className="d-flex d-flex-row two-columns h-100">
          <div className="column">
            <h2>Academics</h2>
            <div className="seperator" />
            <div className="desk-content tour-content tour-content-list">
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"Brighter minds are the evolution of a series of intriguing thoughts"}
                paraClass={"contact-icon-info para-class"}
              />
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"Knowledge, thoughts, and wisdom triangulating to a brighter future"}
                paraClass={"contact-icon-info para-class"}
              />
            </div>
          </div>
        </div>
        
        {/* <AcademicSlider /> */}
        
      </div>
    </>
  );
}
