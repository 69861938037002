import { Route, Switch } from "react-router";
import Footer from "../components/footer/footer";
import Navbar from "../components/navbar/navbar";
import routes from "../routes/App.routes";

export default function AppLayout() {
  const renderRoutes = () => {
    return routes.map((prop, key) => {
      return (
        <Route key={key} exact component={prop.component} path={prop.url} />
      );
    });
  };

  return (
    <div>
      <Navbar />
      <div className="content">
        <Switch>{renderRoutes()}</Switch>
      </div>
      <Footer />
    </div>
  );
}
