import React from "react";
import { RenderIconPara } from "../../common/Common";

import "./Fun.css";
import { FiCheck } from "react-icons/fi";
import FunSlider from "./FunSlider";


export default function Fun() {
  return (
    <>
      <div className="container">
        <div className="d-flex d-flex-row two-columns h-100">
          <div className="column">
            <h2>Fun</h2>
            <div className="seperator" />
            <div className="desk-content tour-content tour-content-list">
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"Fun at learning makes the process more interesting and engaging."}
                paraClass={"contact-icon-info para-class"}
              />
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"When the fun stops, learning a better way is to have them together."}
                paraClass={"contact-icon-info para-class"}
              />
            </div>
          </div>
        </div>
        
        {/* <FunSlider /> */}
        
      </div>
    </>
  );
}
