import { ParallaxBanner } from "react-scroll-parallax";
import Overlay from "../../homepage/banner/Overlay";
import "./Desk.css";

export default function Desk() {
  return (
    <ParallaxBanner
      className="your-class"
      layers={[
        {
          image:
            "https://res.cloudinary.com/du0fuqphz/image/upload/v1639455888/avani-public/images/home-image-3.jpg",
          amount: 0.2,
        },

      ]}
      style={{
        height: "700px",
      }}
    >
      <Overlay />
      <div className="parallax-block parallax-content">
        <h1 className="parallax-elements">About Us </h1>
        <div className="separator-parallax parallax-elements" />
        <div className="parallax-description parallax-elements">
          <p>
            Our educational framework fosters the multifaceted talents of young
            minds, enriches the values, wisdom and makes them better citizens of
            tomorrow.
          </p>
        </div>
      </div>
    </ParallaxBanner>
  );
}
