import React from "react";
import { RenderIconPara } from "../../common/Common";

import "./Skill.css";
import { FiCheck } from "react-icons/fi";
import SkillSlider from "./SkillSlider";


export default function Skill() {
  return (
    <>
      <div className="container">
        <div className="d-flex d-flex-row two-columns h-100">
          <div className="column">
            <h2>Skill-based work</h2>
            <div className="seperator" />
            <div className="desk-content tour-content tour-content-list">
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"Will of the students to demonstrate their skill"}
                paraClass={"contact-icon-info para-class"}
              />
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"Students sharpening their skills to cut into the world of creativity"}
                paraClass={"contact-icon-info para-class"}
              />
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"Students are polishing their skills to skate through the ice of innovation"}
                paraClass={"contact-icon-info para-class"}
              />
               <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"Students upgrade their skills to soldier through the world of possibilities."}
                paraClass={"contact-icon-info para-class"}
              />
            </div>
          </div>
        </div>
        
        {/* <SkillSlider /> */}
        
      </div>
    </>
  );
}
