import ContentWrapper from "../../Wrapper/ContentWrapper";
import AboutSchool from "../AboutSchool/AboutSchool";
import Campus from "../Campus/Campus";
import Gallery from "../Campus/Gallery/gallery";
import Teacher from "../Campus/Teacher/Teacher";
import { SeniorManagementTeam } from "../Campus/Teacher/Teachers";
import Desk from "../Desk/Desk";
import DeskLetter from "../DeskLetter/DeskLetter";
import MandatoryDisclosure from "../disclosure/MandatoryDisclosure";
import SchoolManagement, {
  ManagmementMember,
} from "../Management/SchoolManagement";
import VisionBlock from "../VisionBlock/VisionBlock";

const PrincipalContent = {
  Title: `From Principal's Desk`,
  link: "principal-message",
  img: "https://res.cloudinary.com/du0fuqphz/image/upload/v1639455887/avani-public/images/home-image-2.jpg",
  Content: () => (
    <>
      <p className="quote">
        “Never stop learning, because life never stops teaching”
      </p>
      <p className="title-para">
        I’m truly honoured to be the principal at Avani Public School, one of
        the exceptional institutions in its division as it is distinctive in its
        style.I believe every child deserves the best possible education.
      </p>
      <p>
        Team Avani intends to create a student-centric environment and nurture
        holistic development. Learning is amusement and elation.Students will be
        confronted and betrothed through authentic learning windows that inspire
        them to evolve inventiveness, credence and liability to become
        independent and ethical life-long learners. The school pledged for
        transformation in education where academic excellence is our major
        thrust. The school is also devoted to preparing the students for life
        and encouraging them to be socially relevant. Our optimistic approach
        allows students to advance based on their ability to master a skill or
        competency at their own pace regardless of environment .Our method is
        tailored to meet different learning abilities and can lead to more
        efficient student outcomes. Our aim is to offer a balanced education
        which prepares pupils for adult responsibilities in a new generation.I
        endeavour to improve the effectiveness in the school culture by
        providing innovative professional development and reading professional
        materials to my team.
      </p>
      <p>
        Team Avani pays gratitude to the parents and their round the clock
        support.
      </p>
      <p>Teamwork Works</p>
      <p>Regards</p>
      <p>Malini A</p>
      <p>Principal</p>
    </>
  ),
};

const CorrespondentContent = {
  Title: `Management's Desk`,
  link: "management-desk",
  img: "https://res.cloudinary.com/du0fuqphz/image/upload/v1639455888/avani-public/images/home-image-5.jpg",
  Content: () => (
    <>
      <p className="title-para">A warm welcome to Avani family!</p>
      <p className="title-para">
        Avani Public School was started in the year 2016 with the motto of
        providing education in a safe, positive and nurturing environment. Our
        educational framework fosters the multifaceted talents of young minds,
        enriches the values, wisdom and makes them better citizens of tomorrow.
      </p>
      <p>With warm regards</p>
      <p>Chairman</p>
      <p>K.Vijayakumar.</p>
      <h3>Words from the Secretary </h3>
      <p className="quote">
        “The aim of education is knowledge, not of facts but values. - William
        Ralph Inge”
      </p>
      <p>
        I am excited and looking forward to beginning our journey together and
        inviting you to become active participants in our learning community.{" "}
      </p>
      <p>Best Wishes</p>
      <p>N. Sanghamithra Jayakrishna</p>
    </>
  ),
};

export default function AboutLanding() {
  return (
    <>
      <Desk />
      <AboutSchool />
      <VisionBlock />
      <ContentWrapper>
        <DeskLetter {...CorrespondentContent} />
        <DeskLetter {...PrincipalContent} />
        <div className="container" id="faculty">
          <h1>Faculty</h1>
          <div className="seperator" />
          <Teacher />
        </div>
        <SchoolManagement />
      </ContentWrapper>
    </>
  );
}
