import React from "react";
import { RenderIconPara } from "../../common/Common";

import "./Commemorate.css";
import CommemorateSlider from "./CommemorateSlider";
import { FiCheck } from "react-icons/fi";

export default function Commemorate() {
  return (
    <>
      <div className="container">
        <div className="d-flex d-flex-row two-columns h-100">
          <div className="column">
            <h2>Commemoration</h2>
            <div className="seperator" />
            <div className="desk-content tour-content tour-content-list">
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"Students understand their duty to society."}
                paraClass={"contact-icon-info para-class"}
              />
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"Students positively impact the lives of others."}
                paraClass={"contact-icon-info para-class"}
              />
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={
                  "Students understand their service to others that leads to a greater society."
                }
                paraClass={"contact-icon-info para-class"}
              />
               <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={"Service which is rendered without joy helps neither the servant nor the served – Mahatma Gandhi"}
                  paraClass={"contact-icon-info para-class"}
                />
                 <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={"The future depends on what you do today - – Mahatma Gandhi"}
                  paraClass={"contact-icon-info para-class"}
                />
            </div>
          </div>
        </div>

        {/* <CommemorateSlider /> */}
      </div>
    </>
  );
}
