import { FiCheck } from "react-icons/fi";
import { RenderIconPara } from "../../../common/Common";

export const Facilities = [
  {
    url: "https://res.cloudinary.com/du0fuqphz/image/upload/v1640066470/avani-public/images/gallery/image24.jpg",
    name: "Infrastructure",
    shortText: 'A well planned & aesthetically outlined',
    panorma:
      "https://360photoconverter.com/view/968228589.jpg",
    content: () => (<>A well planned and aesthetically outlined infrastructure. Avani proudly bestows the
        facilities to serve the growing and challenging needs of the students and to make
        education a highly exciting and rewarding experience.</>),
  },
  {
    url: "https://res.cloudinary.com/du0fuqphz/image/upload/v1640066469/avani-public/images/gallery/image19.jpg",
    name: "Classrooms",
    shortText: 'Aerate, Spacious, Well equipped',
    panorma:
      "https://res.cloudinary.com/du0fuqphz/image/upload/v1640066469/avani-public/images/gallery/image19.jpg",
    content: () => (
      <>
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={"Aerate"}
          paraClass={"contact-icon-info para-class"}
        />
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={"Spacious"}
          paraClass={"contact-icon-info para-class"}
        />
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={"Well equipped"}
          paraClass={"contact-icon-info para-class"}
        />
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={"Grades 1 to 5 are provided with the class library"}
          paraClass={"contact-icon-info para-class"}
        />
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={"The classrooms are furnished with the lockers"}
          paraClass={"contact-icon-info para-class"}
        />
      </>
    ),
  },
  {
    url: "https://res.cloudinary.com/du0fuqphz/image/upload/v1640066470/avani-public/images/gallery/image28.jpg",
    name: "Library",
    shortText: 'Well Organized, Safe Storage & Easy access',
    panorma: "https://res.cloudinary.com/du0fuqphz/image/upload/v1640066470/avani-public/images/gallery/image28.jpg",
    content: () => (
        <>
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={"Aerate"}
          paraClass={"contact-icon-info para-class"}
        />
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={"accommodate multiple science disciplines could provide both educational and practical benefits"}
          paraClass={"contact-icon-info para-class"}
        />
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={`Adequate facilities, equipment, and supplies for laboratory experiences are
          inequitably distributed`}
          paraClass={"contact-icon-info para-class"}
        />
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={`Specialized space for carrying out laboratory experiences`}
          paraClass={"contact-icon-info para-class"}
        />
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={`with well-organised`}
          paraClass={"contact-icon-info para-class"}
        />
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={`safe storage with easy access`}
          paraClass={"contact-icon-info para-class"}
        />
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={`an accessible outdoor space`}
          paraClass={"contact-icon-info para-class"}
        />
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={` Maintaining student safety during laboratory experiences is the main
          concern`}
          paraClass={"contact-icon-info para-class"}
        />
      </>
    )
  },
  {
    url: "https://res.cloudinary.com/du0fuqphz/image/upload/v1640066470/avani-public/images/gallery/image26.jpg",
    name: "Sports",
    shortText: 'The juncture of Physical exertion & skill set.',
    panorma:
      "https://res.cloudinary.com/du0fuqphz/image/upload/v1640066470/avani-public/images/gallery/image26.jpg",
    content: () => (
        <>
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={"Space where students think as strategists!"}
          paraClass={"contact-icon-info para-class"}
        />
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={"To develop the team spirit - It’s not ‘ME’, It’s ‘US’ now!"}
          paraClass={"contact-icon-info para-class"}
        />
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={"The juncture of Physical exertion and their skill set."}
          paraClass={"contact-icon-info para-class"}
        />
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={"Grades 1 to 5 are provided with the class library"}
          paraClass={"contact-icon-info para-class"}
        />
        <RenderIconPara
          icon={<FiCheck className="icon icon-primary-feather" />}
          content={"Demonstrate the knowledge and skills necessary to succeed as a professional in the chosen sport discipline"}
          paraClass={"contact-icon-info para-class"}
        />
      </>
    )
  },
];
