import { IMAGES } from "../../images";
import TestimonialSlider from "../testimonials/TestimonialSlider";

export default function SecondBanner({ height }) {
  return (
    <>
      <div className="column" style={{ height: height, overflow:'hidden' }}>
        <div className="d-flex d-flex-column full-height">
          <div className="row bg-red pos-relative" style={{ height: height / 2 }}>
              <TestimonialSlider height={height/2}  />
          </div>
          <div className="row">
            <div
              className="d-flex d-flex-row full-height"
              style={{ minHeight: height / 2 }}
            >
              <TextContent />
              <ImageContent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function TextContent() {
  return (
    <div className="column justify-center align-center d-flex content-block">
      <div className="content-padding">
        <h2>
          Learn new things.
          <a href="#">
            <span style={{ color: "#ff6e19", fontFamily: "PoppinsBold"}}> Get new skills. </span>
          </a>
          Join us.
        </h2>
      </div>
    </div>
  );
}

function ImageContent() {
  return (
    <div className="column justify-center align-center d-flex">
      <img
        width="1024"
        height="1050"
        src={IMAGES.home.image4}
        className="attachment-full size-full"
        alt="a"
      />
    </div>
  );
}
