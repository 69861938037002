import React from "react";
import { RenderIconPara } from "../../common/Common";

import "./Sports.css";
import SportsSlider from "./SportsSlider";
import { FiCheck } from "react-icons/fi";


export default function Sports() {
  return (
    <>
      <div className="container">
        <div className="d-flex d-flex-row two-columns h-100">
          <div className="column">
            <h2>Sports</h2>
            <div className="seperator" />
            <div className="desk-content tour-content tour-content-list">
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"Space where students think as strategists!"}
                paraClass={"contact-icon-info para-class"}
              />
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"It’s not “Me”, It “US” now!!"}
                paraClass={"contact-icon-info para-class"}
              />
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"The Juncture of Physical exertion and their skill set"}
                paraClass={"contact-icon-info para-class"}
              />
            </div>
          </div>
        </div>
        
        {/* <SportsSlider /> */}
        
      </div>
    </>
  );
}
