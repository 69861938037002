import { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import Modal from "../../../Modal/Modal";
import FacilityPanroma from "./FacilityPanorama";
import classnames from 'classnames';

export default function FacilityDetail({ facility, show, onClose }) {
  const [toggle, setToggle]  = useState(false);
  useEffect(() => {
      if(show) setTimeout(() =>{
          setToggle(true); 
      },200)
      return () => setToggle(false); 
  }, [show])
 
  return (
    <Modal show={show} onClose={onClose}>
      <div className="d-flex d-flex-column h-100" style={{ flex: 1 }}>
        <div className="d-flex d-flex-row img-flex h-100">
          <div className="img-content transparent">
              <div className="img-holder">
                <img src={facility.url} />
              </div>
          </div>
          <div className={classnames("img-description", {'is-open-bar': toggle })} style={{ backgroundColor: "white" }}>
          <h2>Facilities</h2>
          <div className="seperator" />
          <h3>{facility?.name}</h3>
          <div className="desk-content tour-content modal-detail">
            <p className="title-para sub-para">
              <facility.content />
            </p>
          </div>
          </div>
          <div className="close" onClick={() => {setToggle(false); setTimeout(() => {onClose()},300)}}>
              <div>
                  <GrClose className="icon" />
              </div>
          </div>

        </div>
      </div>
    </Modal>
  );
}
