import { FiCheck } from "react-icons/fi";
import Calendar from "../calendar/Calendar";
import { RenderIconPara } from "../common/Common";
import HeaderContent from "../header/header.content";
import "./curriculum.css";

export default function Curriculum() {
  return (
    <>
      <HeaderContent content={"Curriculum"} />
      <div className="content-wrapper" id="montessori">
        <div className="container">
          <div className="d-flex d-flex-row two-columns" style={{marginBottom: 40}}>
            <div className="column">
              <h3>Montessori syllabus</h3>
              <p>
                The Montessori method of education, developed by Dr Maria
                Montessori, is a child-centred educational approach based on
                scientific observations of children from birth to adulthood. The
                Montessori environment is specifically prepared to meet the
                child's developmental needs at the right time. The Montessori
                environment respects and protects the child's rhythm of life.
              </p>
              <p>
                <b>Practical Life</b>
              </p>
              <p>
                Children are naturally interested in activities they have
                witnessed like getting dressed, pouring without spilling. This
                helps children develop concentration and coordinated movement.
              </p>
              <p>
                <b>Sensorial</b>
              </p>
              <p>
                Children are sensorial learners. They absorb everything through
                their senses like touching, seeing, tasting, listening which
                helps to improve their cognitive skills.
              </p>
              <p>
                <b>Language Development</b>
              </p>
              <p>
                Through a variety of language activities and materials, children
                become familiar with the sound and symbols, phonics and begin
                the process of writing, which naturally leads to reading.
              </p>
              <p>
                <b>Mathematics</b>
              </p>
              <p>
                Children begin to explore the mathematical relationship for
                abstract reasoning problem strategies and confidence in using
                numbers in everyday life.
              </p>
              <p>
                <b>Fine Motor</b>
              </p>
              <p>
                Fine Motor skills are being acquired and refined through the use
                of specially selected manipulative materials which improve
                eye/hand coordination, finger dexterity, strength and wrist
                rotation.
              </p>
              <p>
                <b>Social-Emotional development</b>
              </p>
              <p>
                The social behaviour that flourishes in our school program is
                responsible for one’s own behaviour for others grace and
                courtesy and care of the environment.
              </p>
              <p>
                <b>Gross Motor</b>
              </p>
              <p>
                Movement is a key component in a children’s ability to learn.
                Gross motor skill helps children to develop large muscle control
                in arms, legs and the entire body
              </p>
              <p>
                <b>Cosmic Education</b>
              </p>
              <p>
                Cosmic education gives the children the opportunity to explore
                and acquire knowledge of the universe which enables the children
                to understand interrelationships that exist in the world around
                them.
              </p>
            </div>
          </div>
          <div className="d-flex d-flex-row two-columns" style={{marginTop: 40}}>
            <div className="column" id="cbse" >
              <h3>CBSE Syllabus</h3>
              <div className="desk-content tour-content tour-content-list">
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={
                    "The session incorporating and integrating both scholastic and co-scholastic activities."
                  }
                  paraClass={"contact-icon-info para-class"}
                />
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={
                    "Co-Scholastic assessment, Diagnosing deficient areas of students learning, Remediation measures, Recordkeeping"
                  }
                  paraClass={"contact-icon-info para-class"}
                />
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={"Open Text-based Assessment"}
                  paraClass={"contact-icon-info para-class"}
                />
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={"Preparing evidence of assessments."}
                  paraClass={"contact-icon-info para-class"}
                />
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={
                    "Helping every student move from one level to the next higher level with the active support and guidance of the teacher."
                  }
                  paraClass={"contact-icon-info para-class"}
                />
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={
                    "Bringing out lesson plan booklets based on Bloom’s taxonomy covering multiple intelligences and incorporating the same in regular lesson planning to cater to the needs of every child"
                  }
                  paraClass={"contact-icon-info para-class"}
                />
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={
                    "Regular analysis of results, regular monitoring and follow up in the classroom.  Adoption of digital classrooms.  Identification of learning deficiencies and undertaking regular remedial measures."
                  }
                  paraClass={"contact-icon-info para-class"}
                />
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={
                    "Inculcation of reading habit through June  “Reading books programme”.  Multidisciplinary investigatory projects as an alternate pedagogical strategy.  Encouraging students to indulge in research(weekly) and go beyond the prescribed syllabus while doing their projects.  Publication of school Newsletter"
                  }
                  paraClass={"contact-icon-info para-class"}
                />
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={
                    "Sharing expectations as well as assessment parameters with the students for better participatory learning"
                  }
                  paraClass={"contact-icon-info para-class"}
                />
              </div>
              <h3>Grade 1 to 5</h3>
              <div className="desk-content tour-content tour-content-list">
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={"English – Next"}
                  paraClass={"contact-icon-info para-class"}
                />
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={"Math – Madhubun, Ratna Sagar"}
                  paraClass={"contact-icon-info para-class"}
                />
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={"EVS – Cambridge, Oxford"}
                  paraClass={"contact-icon-info para-class"}
                />
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={"V.E – Living in Harmony"}
                  paraClass={"contact-icon-info para-class"}
                />
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={"G.K – Viva"}
                  paraClass={"contact-icon-info para-class"}
                />
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={"ICT – Cambridge"}
                  paraClass={"contact-icon-info para-class"}
                />
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={"II Lang – Tamil only Tamil Aruvi"}
                  paraClass={"contact-icon-info para-class"}
                />
              </div>
              <p>
                *As per (Tamil Nadu Government rule) III Lang – Hindi only
                (Macmillan)
              </p>
              <h3>Grade 6 to 8</h3>
              <div className="desk-content tour-content tour-content-list">
                <RenderIconPara
                  icon={<FiCheck className="icon icon-primary-feather" />}
                  content={"NCERT BOOKS"}
                  paraClass={"contact-icon-info para-class"}
                />
              </div>
            </div>

            <div className="column">
              <Calendar title="Grade Calendar" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
