import { FiActivity, FiAirplay, FiCheck, FiFileText, FiMail, FiPaperclip, FiPieChart, FiUsers, FiAnchor } from "react-icons/fi";

export const Disclosure = [
    {
        name: 'School Affidavit',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1639456001/avani-public/disclosures/SchoolAffidavit.pdf',
        Icon: () => <FiFileText className="doc-icon" />
    },
    {
        name: 'Annual Report',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1687683414/avani-public/disclosures/ANNUAL_REPORT_2022-23.pdf',
        Icon: () => <FiPieChart className="doc-icon" />
    },
    {
        name: 'SMC Members',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1712939018/avani-public/2024/24-25_SMC_website.pdf',
        Icon: () => <FiUsers className="doc-icon" />
    },
    {
        name: 'PTA members',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1712939018/avani-public/2024/PTA.pdf',
        Icon: () => <FiUsers className="doc-icon" />
    },
    {
        name: 'Teacher Training Manual',
        url: 'https://docs.google.com/spreadsheets/d/1blWyG6mIHw8o5mJUuWsKKhxTuyVd555Fe4SAPf9D--I/edit?usp=drivesdk.',
        Icon: () => <FiAirplay className="doc-icon" />
    },
    {
        name: 'Transfer Certificate',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1643338838/avani-public/disclosures/TC_avani_public_school.pdf',
        Icon: () => <FiMail className="doc-icon" />
    },
    {
        name: 'Annual Sports Report',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1712900034/avani-public/2024/Annual_Sports_Report.pdf',
        Icon: () => <FiActivity className="doc-icon" />
    }
]

export const Documents = [
    {
        id: '01',
        name: 'COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1712900039/avani-public/2024/CBSE-1.pdf',
    },
    {
        id: '02',
        name: 'COPIES OF SOCIETIES / TRUST / COMPANY REGISTRATION / RENEWAL CERTIFICATE, AS APPLICABLE',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1671626252/avani-public/disclosures/Trust_deed.pdf',
    },
    {
        id: '03',
        name: 'COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED IF APPLICABLE BY THE STATE GOVT/UT',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1671626248/avani-public/disclosures/NOC.pdf',
    },
    {
        id: '04',
        name: 'COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT 2009 AND ITS RENEWAL IF APPLICABLE',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1675919460/avani-public/disclosures/recognition-certificate.pdf',
    },
    {
        id: '05',
        name: 'COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1671626245/avani-public/disclosures/Building_License.pdf',
    },
    {
        id: '07',
        name: 'COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1712900037/avani-public/2024/Fire_Certificate.pdf',
    },
    {
        id: '08',
        name: 'COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR THE AFFILIATION/UPGRADATION/EXTENSION OF AFFILIATION OR SELF CERTIFICATION BY SCHOOL',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1671799391/avani-public/disclosures/deo_certificate.pdf',
    },
    {
        id: '09',
        name: 'COPIES OF VALID WATER, HEALTH CERTIFICATES',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1712900037/avani-public/2024/Drinking_Water_Certificate.pdf',
    },
    {
        id: '10',
        name: 'COPIES OF VALID SANITARY CERTIFICATE',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1712900042/avani-public/2024/SANITARY_CERTIFICATE.pdf',
    },
    {
        id: '11',
        name: 'COPY OF LAND CERTIFICATE',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1672054219/avani-public/disclosures/land_certificate.pdf',
    },
    {
        id: '12',
        name: 'COPY OF LEASE DEED',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1671626258/avani-public/disclosures/Lease_deed.pdf',
    },
     {
        id: '13',
        name: 'COPY OF TEACHING STAFF DETAILS',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1712900035/avani-public/2024/Teaching_Staff_Details_pdf-1.pdf',
    },
    {
        id: '14',
        name: 'COPY OF CBSE TEACHER TRAINING',
        url: 'https://res.cloudinary.com/du0fuqphz/image/upload/v1712900669/avani-public/2024/CBSE_Teacher_Training-2-54_2024.pdf',
    },
]
