import React from "react";
import { RenderIconPara } from "../../common/Common";

import "./Field.css";
import FieldSlider from "./FieldSlider";
import { FiCheck } from "react-icons/fi";

export default function Field() {
  return (
    <>
      <div className="container">
        <div className="d-flex d-flex-row two-columns h-100">
          <div className="column">
            <h2>Fieldwork</h2>
            <div className="seperator" />
            <div className="desk-content tour-content">
              <p className="title-para sub-para">
                Fun-filled led fieldwork unleashes the curiosity in the
                student’s mind. They studied, and now they see what they have
                studied.
              </p>
            </div>
            <div className="desk-content tour-content tour-content-list">
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={
                  "Expression of the inner self of the little one’s upscaling the heights of innovation"
                }
                paraClass={"contact-icon-info para-class"}
              />
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"Little ones with their innovation hats ON!!"}
                paraClass={"contact-icon-info para-class"}
              />
              <RenderIconPara
                icon={<FiCheck className="icon icon-primary-feather" />}
                content={"Artists of tomorrow!!"}
                paraClass={"contact-icon-info para-class"}
              />
            </div>
          </div>
        </div>

        {/* <FieldSlider /> */}
      </div>
    </>
  );
}
