import { useState } from "react";
import "./nav-styles.css";
import { FiMenu, FiX } from "react-icons/fi";
import SideNavAccordian from "./sidenavaccordian";
import Logo from "./Logo";
import { Link, NavLink, withRouter } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { AiFillFacebook, AiOutlineYoutube } from "react-icons/ai";
import { IoLogoYoutube } from "react-icons/io";

const Navbar = ({ history }) => {
  const [sideNavOpen, setsideNavOpen] = useState(false);
  const [activeSideBar, setActiveSideBar] = useState(null);

  const OpenNav = () => {
    setsideNavOpen(!sideNavOpen);
  };

  history.listen((location, action) => {
    if (action === "PUSH") setsideNavOpen(false);
  });

  const onChangeSideBar = (key) => {
    setActiveSideBar(key);
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <div className="nav-holder">
      <div className="nav-wrapper">
        <NavLink className="pos-relative" style={{ flex: 1 }} to="/">
          <Logo />
          <div className="affiliation-box">
          Affiliated to CBSE, New Delhi. Affiliation No. <span className="text-primary">1931552</span>
        </div>
        </NavLink>
        
        <div>
          <ul className="menu-list-wrapper">
            <li className="menu-item">
              <NavLink exact to="/" className="menu-text" activeClassName="active" exact>
                Home
              </NavLink>
            </li>
            <li className="menu-item-dropdown">
              <NavLink
                to="/about-us"
                className="menu-text"
                activeClassName="active"
                exact
              >
                About Us
              </NavLink>
              <ul className="subnav-wrapper">
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/about-us#about"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    About
                  </NavHashLink>
                </li>
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/about-us#vision"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    Vision
                  </NavHashLink>
                </li>
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/about-us#mission"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    Mission
                  </NavHashLink>
                </li>
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/about-us#management-desk"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    Management's Desk
                  </NavHashLink>
                </li>
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/about-us#principal-message"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    Principal's message
                  </NavHashLink>
                </li>
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/about-us#faculty"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    Faculty
                  </NavHashLink>
                </li>
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/about-us#smc"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    SMC
                  </NavHashLink>
                </li>
              </ul>
            </li>
            <li className="menu-item-dropdown">
              <NavLink
                to="/our-facilities"
                className="menu-text"
                activeClassName="active"
                scroll={(el) => scrollWithOffset(el)}
                exact
              >
                Our Facilities
              </NavLink>
              <ul className="subnav-wrapper">
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/our-facilities#campus"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    Campus
                  </NavHashLink>
                </li>
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/our-facilities#facilities"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    Facility
                  </NavHashLink>
                </li>
              </ul>
            </li>
            <li className="menu-item-dropdown">
              <NavLink
                to="/admissions"
                className="menu-text"
                activeClassName="active"
                exact
              >
                Admissions
              </NavLink>
              <ul className="subnav-wrapper">
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/admissions#campus"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    Campus
                  </NavHashLink>
                </li>
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/admissions#criteria"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    Criteria
                  </NavHashLink>
                </li>

                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/admissions#apply"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    Apply
                  </NavHashLink>
                </li>
              </ul>
            </li>
            <li className="menu-item-dropdown">
              <NavLink
                to="/our-curriculum"
                className="menu-text"
                activeClassName="active"
                scroll={(el) => scrollWithOffset(el)}
                exact
              >
                Curriculum
              </NavLink>
              <ul className="subnav-wrapper">
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/our-curriculum#montessori"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    Montessori syllabus
                  </NavHashLink>
                </li>
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/our-curriculum#cbse"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    CBSE syllabus
                  </NavHashLink>
                </li>
              </ul>
            </li>
           
            <li className="menu-item">
              <a
                className="menu-text"
                href="https://www.eduqfix.com/PayDirect/#/student/pay/J5zwIWqUMn7gK8qAYkSaRCdpnFYpm+IAlyKi7EY8NLke0l4gJ0gl7haadQXIWarS/4716"
                target="_blank"
              >
                Pay Fees
              </a>
            </li>
            <li className="menu-item">
              <NavLink to="/gallery" className="menu-text" activeClassName="active" exact>
                Gallery
              </NavLink>
            </li>
            <li className="menu-item-dropdown">
              <NavLink
                to="/school-information"
                className="menu-text"
                activeClassName="active"
                exact
              >
                School Info
              </NavLink>
              <ul className="subnav-wrapper">
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/school-information#school-info"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    School Information
                  </NavHashLink>
                </li>
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/school-information#disclosure"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    Mandatory Disclosure
                  </NavHashLink>
                </li>
              </ul>
            </li>
            <li className="menu-item-dropdown">
              <NavLink
                to="/contact-us"
                className="menu-text"
                activeClassName="active"
                exact
              >
                Contact
              </NavLink>
              <ul className="subnav-wrapper">
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/contact-us#contact"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    Contact Us
                  </NavHashLink>
                </li>
                <li className="submenu-text-wrapper">
                  <NavHashLink
                    smooth
                    to="/contact-us#apply"
                    activeClassName="selected"
                    activeStyle={{ color: "orange" }}
                    className="submenu-text"
                    scroll={(el) => scrollWithOffset(el)}
                    exact
                  >
                    Apply
                  </NavHashLink>
                </li>
              </ul>
            </li>
            <li className="menu-item-icon" onClick={OpenNav}>
              <FiMenu className="nav-icon" />
            </li>
          </ul>
        </div>
      </div>
      <>
        {sideNavOpen ? (
          <div className="side-nav-wrapper">
            <div onClick={OpenNav}>
              <FiX
                style={{
                  fontSize: 22,
                  color: "white",
                  padding: 20,
                  float: "right",
                }}
              />
            </div>
            <div className="side-nav-inner">
              <div>
                <div className="logo-side-nav pos-relative">
                  <Link to="/">
                    <Logo />
                  </Link>
                </div>
                <div className="seperator"></div>
                <div>
                  <p className="side-nav-text-content">
                    Avani Public school believe each kid is different in their
                    way. Their talents are unique and need the right landscape
                    to bring them out.
                  </p>
                </div>
              </div>
              <div>
                <ul className="side-nav-menu-wrapper">
                  <SideNavAccordian
                    title="About Us"
                    url={"/about-us"}
                    subtitles={[
                      {
                        title: `About`,
                        to: "/about-us#about",
                      },
                      {
                        title: `Vision`,
                        to: "/about-us#vision",
                      },
                      {
                        title: `Mission`,
                        to: "/about-us#mission",
                      },
                      {
                        title: `Management's Desk`,
                        to: "/about-us#management-desk",
                      },
                      {
                        title: `Principal's message`,
                        to: "/about-us#principal-message",
                      },
                      {
                        title: `Faculty`,
                        to:"/about-us#faculty",
                      },
                      {
                        title: `SMC`,
                        to: "/about-us#smc",
                      },
                    ]}
                    activeKey={1}
                    activeSideBar={activeSideBar}
                    onChangeSideBar={onChangeSideBar}
                  />
                  <SideNavAccordian
                    title="Admissions"
                    url={"/admissions"}
                    subtitles={[
                      {
                        title: `Campus`,
                        to: "/admissions#campus",
                      },
                      {
                        title: `Criteria`,
                        to: "/admissions#criteria",
                      },
                      {
                        title: `Apply`,
                        to: "/admissions#apply",
                      },
                    ]}
                    activeKey={2}
                    activeSideBar={activeSideBar}
                    onChangeSideBar={onChangeSideBar}
                  />

                  <SideNavAccordian
                    title="Curriculum"
                    url="/our-curriculum"
                    subtitles={[
                      {
                        title: `Montessori syllabus`,
                        to: "/our-curriculum#montessori",
                      },
                      {
                        title: `CBSE syllabus`,
                        to: "/our-curriculum#cbse",
                      },
                    ]}
                    activeKey={3}
                    activeSideBar={activeSideBar}
                    onChangeSideBar={onChangeSideBar}
                  />
                  <SideNavAccordian
                    title="Pay Fees"
                    url="https://www.eduqfix.com/PayDirect/#/student/pay/J5zwIWqUMn7gK8qAYkSaRCdpnFYpm+IAlyKi7EY8NLke0l4gJ0gl7haadQXIWarS/4716"
                    target="_blank"
                    subtitles={["PayFees"]}
                    activeKey={4}
                    activeSideBar={activeSideBar}
                    onChangeSideBar={onChangeSideBar}
                  />
                  <SideNavAccordian
                    title="School Info"
                    url="/school-information"
                    subtitles={[
                      {
                        title: `School Information`,
                        to: "/school-information#school-info",
                      },
                      {
                        title: `Mandatory Disclosure`,
                        to: "/school-information#disclosure",
                      },
                    ]}
                    activeKey={3}
                    activeSideBar={activeSideBar}
                    onChangeSideBar={onChangeSideBar}
                  />
                  <SideNavAccordian
                    title="Contact us"
                    url="/contact-us"
                    subtitles={[
                      {
                        title: `Contact Us`,
                        to: "/contact-us#contact",
                      },
                      {
                        title: `Apply`,
                        to: "/contact-us#contact",
                      },
                    ]}
                    activeKey={6}
                    activeSideBar={activeSideBar}
                    onChangeSideBar={onChangeSideBar}
                  />
                  <div>
                    <a
                      href="https://m.facebook.com/pages/category/Education/Avani-Public-School-137648843541640/"
                      style={{ fontSize: "32px", marginRight: 10, color: 'white' }}
                    >
                      <AiFillFacebook />
                    </a>
                    <a
                      href="https://youtube.com/channel/UCKEC3E053grTWvQXEyhPAzw"
                      style={{ fontSize: "32px", color: 'white' }}
                    >
                      <IoLogoYoutube />
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

export default withRouter(Navbar);
