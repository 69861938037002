import "./vision.css";

export default function VisionBlock() {
  return (
    <>
      <Vision />
      <Mission />
    </>
  );
}

function Vision() {
  return (
    <div
      className="d-flex d-flex-row two-columns overflow-hidden pos-relative h-100 vision-block"
      style={{ paddingTop: 0 }}
      id="vision"
    >
      <div className="column primary-block"></div>
      <div className="column bg-red">
        <div className="block-content-padding">
          <h2 className="color-white">Vision</h2>
          <p className="color-white">
            To attain Accountability, Responsibility, Multifaceted Personality
            with an optimistic outlook to reach the pinnacle.
          </p>
        </div>
      </div>
    </div>
  );
}

function Mission() {
  return (
    <div
      className="d-flex d-flex-row two-columns overflow-hidden pos-relative h-100 row-reverse"
      style={{ paddingTop: 0 }}
      id="mission"
    >
      <div className="column bg-white mission-bg"></div>
      <div className="column secondary-block">
        <div className="block-content-padding">
          <h2>Mission</h2>
          <p>
            Avani students aspire to accomplish astounding achievements with a
            holistic approach in walks of life.
          </p>
        </div>
      </div>
    </div>
  );
}
