import { useEffect } from "react";
import { Link } from "react-router-dom";
import Overlay from "./Overlay";

export default function FirstBanner({ height }) {
 
  return (
    <>
      <div
        className="column column-holder home-bg"
        style={{ height: height, minHeight: height }}
      > 
          <Overlay />
          <ActionButton />
      </div>
    </>
  );
}

function ActionButton() {
  return (
    <div
      className="action-block"
    >
      <div className="wpb_text_column wpb_content_element ">
        <div className="wpb_wrapper">
          <h1 style={{ color: "#fff" }}>Student Centric Holistic & Safe</h1>
        </div>
      </div>
      <div className="vc_empty_space" style={{ height: "45px" }}>
        <span className="vc_empty_space_inner" />
      </div>
     <Link itemProp="url"
        to="/about-us"
        style={{ color: "#ffffff", borderColor: "#ffffff" }}
        className="btn-simple-link btn-outline">
      <span className="eltdf-btn-text">Read More</span>
     </Link>{" "}
    </div>
  );
}
