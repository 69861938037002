import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import "./Tabs.css";
import classnames from 'classnames';

function TabsList({ parentCallback, CampusDetail }) {
  return (
    <ul className={"tab-list"}>
      <li
        onClick={() => {
          parentCallback("tour");
        }}
      >
        <a className={classnames({'active': CampusDetail === 'tour'})}>
          <HiOutlineArrowNarrowRight className="link-icon icon-primary-feather" />
          Campus Tour
        </a>
      </li>
      <li
        onClick={() => {
          parentCallback("gallery");
        }}
      >
        <a className={classnames({'active': CampusDetail === 'gallery'})}>
          <HiOutlineArrowNarrowRight className="link-icon icon-primary-feather" />
          Facilities
        </a>
      </li>
    </ul>
  );
}

export default TabsList;
