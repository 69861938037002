import { ParallaxBanner } from "react-scroll-parallax";

export default function ProgramHeader() {
  return (
    <ParallaxBanner
      className="your-class"
      layers={[
        {
          image:
            "https://academist.qodeinteractive.com/wp-content/uploads/2018/07/h-instructor-img-1.jpg",
          amount: 0.2,
        },
      ]}
      style={{
        height: "700px",
      }}
    >
      <div className="parallax-block parallax-content">
        <h1 className="parallax-elements">Activities </h1>
        <div className="separator-parallax parallax-elements" />
        <div className="parallax-description parallax-elements">
          <p>
            The multidimensional child-centric approach. It is both interactive
            and pragmatic. We nurture our children to be confident and
            self-sufficient.
          </p>
        </div>
      </div>
    </ParallaxBanner>
  );
}
