import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { style } from "./style";


export default function GoogleMapRender({ height, width }) {
    const containerStyle = {
      width: "100%",
      height: "98%",
    };
  
    const center = {
      lat: 11.0711656,
      lng: 76.9152336,
    };
    return (
      <LoadScript googleMapsApiKey="AIzaSyABKo5cy-Wr0Gr_tdWqJg2b3Xh79Pi1JN0">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
          options={{ styles: style.styles }}
        >
          {/* Child components, such as markers, info windows, etc. */}
          <></>
          <Marker position={center} />
        </GoogleMap>
      </LoadScript>
    );
  }