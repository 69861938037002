

export const RenderIconPara = ({icon, content, paraClass}) => {

    return (
        <div className="contact-icon-holder">
        <div className="icon-holder">
          {icon}
        </div>
        <p className={paraClass} dangerouslySetInnerHTML={{__html: content}}>
           
        </p>
      </div>
    )
}