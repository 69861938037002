import { GrLocation } from "react-icons/gr";
import { IoMdCall, IoIosTimer } from "react-icons/io";
import * as emailjs from "emailjs-com";
import { useState } from "react";
import { useForm } from "react-hook-form";

export default function ContactHomePage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const onSubmit = (data) => {
    setIsLoading(true);
    const { name, email, subject, message } = data;
    let templateParams = {
      from_name: name,
      to_name: name,
      to_email: email,
      subject: subject ? subject : 'Quick Enquiry',
      message: message ? message : 'Quick Enquiry',
    };
    emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_USER_ID
      )
      .then((isSent) => {
        if (isSent.status === 200) {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        alert('Failed to submt your information. Please try again .. ')
      })
    
    reset();
  };
  return (
    <div className="d-flex d-flex-row two-columns h-100 margin-1">
      <div className="column">
        <div className="d-flex d-flex-row two-columns h-100">
          <div className="column homepage-contact-bg">
            <ContactInfo />
          </div>
          <div className="column homepage-contact-form-bg">
            <div className="contact-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <input
                  type="text"
                  placeholder="Name"
                  className="admission-form-input"
                  name="name"
                  {...register("name", { required: true, maxLength: 80 })}
                />
                <input
                  type="text"
                  placeholder="Email"
                  className="admission-form-input"
                  name="email"
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                />
                <input
                  type="phone"
                  placeholder="Phone Number"
                  className="admission-form-input"
                  name="phone"
                  {...register("phone", {
                    required: true,
                    maxLength: 10,
                    type: Number,
                  })}
                />
                {Object.entries(errors).length > 0 &&
                  Object.entries(errors)[0] &&
                  Object.entries(errors)[0][0] && (
                    <p style={{ color: "red" }}>
                      {Object.entries(errors)[0][0]}{" "}
                      {Object.entries(errors)[0][1].type}
                    </p>
                  )}
                {!isLoading && !isSuccess && (
                  <button
                    className="btn btn-primary admission-button"
                    type="submit"
                  >
                    Submit Now
                  </button>
                )}
                { isLoading && (
                  <button
                    className="btn btn-primary admission-button"
                  >
                    Sending  ... 
                  </button>
                )}
              </form>
              {isSuccess && (
                <p style={{ color: "green" }}>
                  Form Submitted Successfully, our team will contact your
                  shortly.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="eltdf-video-button-image pos-relative">
          <img
            width="1024"
            height="525"
            src="https://res.cloudinary.com/du0fuqphz/image/upload/v1639455889/avani-public/images/home-image.jpg"
            class="attachment-full size-full"
            alt="a"
          />{" "}
        </div>
      </div>
    </div>
  );
}

function ContactInfo() {
  return (
    <div className="hompage-contact-info">
      <h3 style={{ color: "white" }}>Contact us </h3>
      <div className="title-line"></div>
      <p>
        <span style={{ fontFamily: "PoppinsRegular", color: "white" }}>
          Feel free to drop your queries{" "}
        </span>
      </p>
      <div className="empty-space" data-height="37px" />
      <div className="contact-icon-holder">
        <div className="icon-holder">
          <GrLocation className="icon" />
        </div>
        <p className="contact-icon-info">
          457, Thudiyalur Pannimadai Road, Coimbatore
        </p>
      </div>
      <div
        className="contact-icon-holder"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="icon-holder">
          <IoMdCall className="icon" />
        </div>
        <a
          className="contact-icon-info"
          href="tel:919092845000"
          style={{ marginTop: -10 }}
        >
           +91 90928 45000
        </a>
      </div>
      <div className="contact-icon-holder">
        <div className="icon-holder">
          <IoIosTimer className="icon" />
        </div>
        <p className="contact-icon-info">Mon - Sat 8.00 - 16.00</p>
      </div>
    </div>
  );
}
